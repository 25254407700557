@import './colors.less';

body {
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: @black-1;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: none;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.hide {
  display: none !important;
}

// Container
.ant-space {
  max-width: 100%;
}
