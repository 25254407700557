.field {
  display: flex;
  flex-direction: column;
  > span {
    &:nth-child(2) {
      display: flex;
      gap: 8px;
      align-items: center;
      :global {
        .anticon {
          position: relative;
          cursor: pointer;
          &.anticon-eye {
            top: -2px;
          }
        }
      }
    }
  }
}
