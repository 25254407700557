@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  display: flex;
  .mainContent {
    margin: 0;
    display: flex;
    border-radius: 10px 0 0 10px;
    min-height: 1px;
    padding: 20px 45px 20px 40px;
    flex: 1;
    max-width: calc(100% - 325px);
    flex-direction: column;
  }
  .leftContent {
    flex: 1;
    margin-right: 1px;
    max-width: 100%;
  }
  .activity {
    width: 325px;
    max-width: 325px;
    min-width: 325px;
  }
  &.isActivityCollapsed {
    .activity {
      width: 80px;
      max-width: 80px;
      min-width: 80px;
    }
    .mainContent {
      max-width: calc(100% - 80px);
    }
  }
  &.hideActivity {
    .mainContent {
      max-width: 100%;
    }
  }
}
.mainContent {
  display: flex;
  .logoWrapper {
    margin-right: 24px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    & + div {
      flex: 1;
    }
    .defaultAvatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
}
.contentWrapper {
  padding: 20px;
  display: flex;
}
.profile {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 16px;
}
.orderInformation {
  padding-left: 124px;
  padding-top: 32px;
}
.checklist {
  margin-top: 40px;
}
