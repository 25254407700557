@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  :global {
    .ant-tabs {
      .ant-tabs-nav {
        padding-right: 232px;
      }
    }
  }
}
.tabHeaderButton {
  position: absolute;
  right: $space-horizontal;
  top: 17px;
}
.mainContentTab {
  background: $white-0;
  padding: $space;
  :global {
    .ant-table {
      .ant-table-thead {
        > tr {
          > th {
            &:first-child {
              padding: 16px 8px;
              text-align: left;
            }
          }
        }
      }
      colgroup {
        col {
          &:first-child {
            min-width: 66px !important;
          }
        }
      }
    }
    .ant-table-cell.ant-table-cell-scrollbar {
      display: none;
    }
  }
  &.isNotFound {
    background: transparent;
  }
}
.tabContentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  :global {
    .ant-input-affix-wrapper {
      max-width: 256px;
    }
  }
  .actions {
    display: flex;
    :global {
      .ant-btn {
        max-width: 100px;
        gap: 4px;
        &.ant-btn-loading {
          max-width: 110px;
        }
        &[disabled] {
          background: #e5e5e5;
          border-color: #d9d9d9;
          color: #595959;
          opacity: 1;
        }
      }
    }
    button {
      box-shadow: none !important;
    }
    > button {
      &:last-child {
        margin-right: 0;
        margin-left: 8px;
      }
    }
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 24px 0 24px;
      height: 32px;
      width: 1px;
      border-right: 1px solid $gray-0;
    }
  }
}
.pagination {
  margin-top: 16px;
}
.notFound {
  width: 100%;
  min-height: 287px;
  padding-top: 80px;
  :global {
    .ant-empty-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      h3 {
        margin-bottom: 32px;
        font-size: 20px;
      }
    }
  }
}
.iconActive {
  > button {
    color: $blue-0 !important;
    border-color: $blue-1-o-1 !important;
  }
}
.filterOverlay {
  min-width: 205px;
  max-width: 205px;
  font-size: 12px;
  padding-bottom: 0;
  :global {
    .ant-dropdown-menu-submenu-expand-icon {
      display: none;
    }
    .ant-dropdown-menu-submenu-title {
      cursor: default;
      background: transparent !important;
      font-size: 12px;
      color: $black-3;
    }
  }
  > li {
    &:first-child {
      border-bottom: 1px solid $white-5;
      font-size: 12px;
    }
    &:last-child {
      background: $white-4;
      padding-bottom: 15px;
    }
  }
}
.filterForm {
  display: flex;
  flex-direction: column;
  > span {
    margin-bottom: 6px;
  }
  > input {
    margin-bottom: 12px;
  }
  :global {
    .ant-input-affix-wrapper.ant-input-is-blue {
      background: $white-0 !important;
    }
    .ant-select {
      width: 100%;
    }
  }
}
