.orderTypeSection {
  display: flex;
  > div {
    &:nth-child(1) {
      min-width: calc(50% - 20px);
      > div > div {
        min-width: 100%;
        width: 100%;
      }
    }
    &:nth-child(2) {
      padding-left: 40px;
      padding-top: 30px;
      > div {
        margin-bottom: 0;
      }
    }
  }
}
.description {
  white-space: break-spaces;
}
