@import 'src/assets/scss/base/colors';

.wrapper {
  border-top: 2px solid rgba(24, 144, 255, 0.5);
  margin-bottom: 24px;
  > div {
    width: calc(100% - 76px);
    margin: 0 auto;
    padding-bottom: 10px;
    display: flex;
    border-bottom: 1px solid rgba(24, 144, 255, 0.5);
    padding-top: 40px;
    align-items: center;
  }
  label {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-right: 16px;
  }
}
.dotNumberWrapper {
  position: relative;
  width: 250px;
  button {
    position: absolute !important;
    top: 4px;
    right: 4px;
  }
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
.errorMessage {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $red-9;
  margin-left: 14px;
}
