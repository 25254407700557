@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  margin-bottom: 18px;
  border-bottom: 1px solid rgba(24, 144, 255, 0.5);
  padding-bottom: 14px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: $black-5;
    margin: 0;
    text-transform: capitalize;
  }
}
