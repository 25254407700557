@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.msaSection {
  margin-top: 36px;
  .statusFilter {
    margin-left: 0;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    line-height: 1;
  }
}
.other {
  margin-top: 36px;
}
