@import 'src/assets/scss/base/colors';

.additionalTitle {
  > a,
  > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $blue-0;
  }
  :global {
    .ant-row.ant-form-item {
      font-weight: normal;
      width: 200px;
      margin-bottom: 0;
    }
  }
}
.wrapper {
  > div {
    &:first-child {
      padding-bottom: 12px !important;
      > span {
        align-self: flex-end;
      }
    }
  }
}
