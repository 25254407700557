@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.header {
  margin-bottom: 28px;
}
.panelHeader {
  > span {
    > span {
      &:nth-child(2) {
        width: calc(100% - 300px);
        max-width: calc(100% - 300px);
        min-width: 200px;
      }
    }
  }
}
.panel {
  :global {
    .ant-collapse-header {
      align-items: flex-start !important;
    }
    .ant-collapse-extra {
      margin-top: 6px;
    }
  }
}
@mixin headerMedium() {
  > span {
    > span {
      &:nth-child(2) {
        > span {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
@mixin headerSmall($margin: 10px) {
  > span {
    > span {
      &:nth-child(1) {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
      }
      &:nth-child(2) {
        margin-top: $margin;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 1709px) {
  .panelHeader {
    &.isActive {
      @include headerMedium();
    }
  }
}
@media screen and (max-width: 1664px) {
  .panelHeader {
    @include headerMedium();
  }
}
@media screen and (max-width: 1509px) {
  .panelHeader {
    &.isActive {
      @include headerSmall();
    }
    &.isEdit {
      @include headerSmall(20px);
    }
  }
}
@media screen and (max-width: 1464px) {
  .panelHeader {
    @include headerSmall();
  }
}
