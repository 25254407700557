@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';
@import 'src/assets/scss/base/mixin';

.tooltip {
  max-width: 500px;
}
.hide {
  display: none !important;
}
.content {
  padding: 34px 16px;
  background: $light-blue-0;
  box-shadow: -1px 0px 0px #1890ff1a;
  height: 100%;
}
.title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  > h3 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 400;
  }
  .titleIcon {
    cursor: pointer;
    display: flex;
    margin-right: 8px;
    margin-left: -8px;
    padding: 1px;
    transform: rotate(-180deg);
    :global {
      .anticon {
        font-size: 22px;
        color: $black-2;
      }
    }
  }
}
.timeline {
  :global {
    .ant-input.ant-input-is-blue {
      background: $white-0 !important;
    }
    .ant-timeline-item-head {
      background-color: transparent;
      &:not(.ant-timeline-item-head-custom) {
        background: $white-0;
      }
    }
    .ant-timeline-item-content {
      padding-top: 2px;
    }
    .ant-timeline-item-last > .ant-timeline-item-content {
      min-height: auto;
    }
    .ant-timeline-item {
      padding-bottom: 14px;
    }
    .ant-timeline-item-last {
      &:before {
        display: none;
      }
    }
    .ant-timeline-item-head-blue {
      width: 14px;
      height: 14px;
      border-width: 1px;
      .anticon {
        font-size: 14px;
        position: relative;
        top: -2px;
        left: 1px;
      }
    }
    .ant-timeline-item-tail {
      border-left: 1px dashed $gray-10;
      left: 6px;
      top: 18px;
      height: calc(100% - 22px);
    }
  }
  > li {
    &:first-child {
      padding-bottom: 24px !important;
      :global {
        .ant-timeline-item-head-blue {
          background: transparent !important;
        }
      }
    }
  }
  .defaultDot {
    :global {
      .ant-timeline-item-head-blue {
        background: $blue-0;
      }
    }
  }
}
.contentBlock {
  font-size: 16px;
  color: $black-4;
  display: flex;
  flex-direction: column;
  > div {
    font-size: 12px;
    color: $gray-10;
    margin-top: 4px;
    display: flex;
    @include ellipsisLongText();
    svg {
      margin-right: 5px;
    }
  }
  .separate {
    margin: 0 8px;
  }
}
.timelineInfo {
  padding-right: 0;
  @include ellipsisLongText();
  &.message {
    font-size: 14px;
    line-height: 22px;
    color: $black-4;
    margin-top: 8px;
    white-space: pre-line;
    :global {
      .anticon {
        color: $blue-0;
      }
    }
  }
}
.addNote {
  padding-top: 4px;
  button {
    margin-top: 8px;
  }
  textarea {
    min-height: 67px;
  }
}
.fieldDisplay {
  text-transform: capitalize;
}
.fieldNextArrow {
  color: $black-4 !important;
  font-size: 12px;
  cursor: pointer;
}
.wrapper {
  transition: all 0.2s;
  &.isCollapsed {
    cursor: pointer;
    .timeline {
      display: none;
    }
    .title {
      flex-direction: column;
      justify-content: center;
      .titleIcon {
        margin: 0;
      }
      > h3 {
        transform: rotate(270deg);
        position: relative;
        top: 28px;
        left: -1px;
      }
    }
  }
}
.noteForm {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
