@import 'src/assets/scss/base/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 330px);
  min-height: 300px;
  justify-content: center;
  img {
    margin-bottom: 62px;
    width: 129px;
    height: 128px;
  }
  p {
    font-size: 20px;
    color: $black-0;
    text-align: center;
    line-height: 24px;
    > span {
      color: $blue-0;
      cursor: pointer;
      font-weight: 500;
    }
  }
}
