@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  padding: 0;
  :global {
    .ant-page-header-heading {
      margin-top: 0 !important;
      .ant-page-header-heading-left {
        margin: 0;
        .ant-page-header-heading-title {
          font-size: 30px;
          color: $black-1;
        }
      }
    }
    .ant-page-header {
      padding: 0 !important;
      .ant-breadcrumb {
        margin: 0;
      }
    }
    .ant-breadcrumb {
      color: $black-2;
      margin-bottom: 0;
      > span {
        &:last-child {
          color: $gray-6;
        }
        .anticon {
          margin-right: 2px;
        }
      }
      a {
        color: $black-2;
      }
    }
  }
  .breadcrumbs {
    &.marginLarge {
      margin-bottom: 24px;
    }
  }
  &.isWhite {
    background: white;
  }
}
