@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 16px 8px 0 8px;
  cursor: pointer;
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: $black-4;
  }
  > span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: $yellow-1;
    margin-left: auto;
    display: flex;
    align-items: center;
    :global {
      .anticon {
        font-size: 12px;
        margin-right: 4px;
      }
    }
  }
}
.info {
  > div {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: $black-5;
    margin-bottom: 8px;
    &:nth-child(1) {
      margin-bottom: 4px;
    }
    &:nth-child(2) {
      color: $gray-14;
    }
    &:nth-child(3) {
      font-size: 8px;
      color: $black-6;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.footer {
  display: flex;
  flex-direction: column;
  cursor: default;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: -8px;
  margin-right: -8px;
  padding-bottom: 8px;
  :global {
    .ant-progress-small {
      line-height: 2px;
      .ant-progress-bg {
        height: 2px !important;
        border-radius: 2px;
      }
    }
  }
  .statusChecklist {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .checklist {
      cursor: pointer;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      color: $blue-0;
      display: inline-block;
      height: 16px;
      > *:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
}
.statusTag {
  height: 17px;
  display: flex;
  cursor: default;
  :global {
    .ant-tag {
      font-size: 8px;
      height: 17px;
      line-height: 17px;
      display: flex;
      align-items: center;
    }
  }
}
.requestedBy {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  > span {
    display: inline-block;
    margin-top: 2px;
  }
  :global {
    .ant-avatar {
      min-width: 24px !important;
      width: 24px;
      height: 24px;
      font-size: 10px;
    }
  }
}
.topContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
