.overlay {
  min-width: 216px;
  max-width: 216px;
  padding-left: 10px;
  :global {
    .ant-popover-inner {
      margin-right: -10px;
      margin-top: -3px;
    }
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner-content {
      position: relative;
    }
  }
}
.addChecklistBtn {
  padding-left: 0;
}
.addItemBtn {
  padding-left: 8px;
  padding-right: 8px;
}
.addBlock {
  margin: 16px -8px -4px -8px;
  > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  :global {
    .ant-input {
      width: 100%;
      padding-bottom: 33px;
    }
  }
}
.addFooter {
  :global {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
.form {
  :global {
    .ant-form-item {
      margin-bottom: 7px;
    }
    .ant-form-item-control-input {
      min-height: 1px;
    }
  }
}
.loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #ffffffb3;
}
