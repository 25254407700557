@import 'src/assets/scss/base/colors';

:global {
  .ant-input-number {
    border-color: $gray-2;
    &:hover {
      border-color: $blue-1;
    }
    &:focus {
      border-color: $blue-1;
      box-shadow: $blue-1-o-1-box-shadow;
    }
  }
  .ant-input-number-focused {
    border-color: $blue-1;
    box-shadow: $blue-1-o-1-box-shadow;
  }
}

.wrapper {
  &.hideHandler {
    width: 62px;
    :global {
      .ant-input-number-handler-wrap {
        display: none;
      }
      .ant-input-number-input-wrap {
        input {
          text-align: center;
        }
      }
    }
    & + .suffix {
      right: 11px;
    }
  }
  &.fullWidth {
    width: 100%;
  }
  &.textLeft {
    :global {
      .ant-input-number-input-wrap {
        input {
          text-align: left;
        }
      }
    }
  }
  &.hasPrefix {
    :global {
      .ant-input-number-input-wrap {
        input {
          padding-left: 22px;
        }
      }
    }
  }
}
.rootWrapper {
  position: relative;
  .prefix,
  .suffix {
    position: absolute;
    top: 6px;
    z-index: 2;
  }
  .prefix {
    left: 11px;
  }
  .suffix {
    right: 25px;
  }
  &.isEmpty {
    .prefix {
      color: #bfbfbf;
    }
  }
}
