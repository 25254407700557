@import 'src/assets/scss/base/colors';
.unCheck {
  color: $red-6;
  span {
    color: $red-6;
  }
}
.checked {
  color: $green-8;
  span {
    color: $green-8;
  }
}
.description {
  position: relative;
  top: -12px;
}
