@import 'src/assets/scss/base/colors';

:global {
  .ant-btn {
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    &:after {
      display: none;
    }
    > .anticon {
      display: flex;
      align-items: center;
    }
    > span {
      line-height: 100%;
    }
  }
  .ant-btn-round {
    border-radius: 2px !important;
  }
  .ant-btn-primary {
    background: $primary-0;
    border-color: $primary-0;
    &:hover {
      background: $primary-0;
      border-color: $primary-0;
    }
    &:active {
      background: $primary-0;
      border-color: $primary-0;
    }
    &:focus {
      background: $primary-0;
      border-color: $primary-0;
    }
  }
  .ant-btn-blue {
    background: $blue-0;
    border-color: $blue-0;
    &:hover {
      background: $blue-0;
      border-color: $blue-0;
    }
    &:active {
      background: $blue-0;
      border-color: $blue-0;
    }
    &:focus {
      background: $blue-0;
      border-color: $blue-0;
    }
  }
  .ant-btn-blue-outlined {
    background: transparent;
    border-color: $blue-0;
    color: $blue-0;
    &:hover {
      background: transparent;
      border-color: $blue-0;
      color: $blue-0;
    }
    &:active {
      background: transparent;
      border-color: $blue-0;
      color: $blue-0;
    }
    &:focus {
      background: transparent;
      border-color: $blue-0;
      color: $blue-0;
    }
  }
  .ant-btn-dark-blue {
    background: $dark-blue-0;
    border-color: $dark-blue-0;
    &:hover {
      background: $dark-blue-0;
      border-color: $dark-blue-0;
    }
    &:active {
      background: $dark-blue-0;
      border-color: $dark-blue-0;
    }
    &:focus {
      background: $dark-blue-0;
      border-color: $dark-blue-0;
    }
  }
  .ant-btn-default {
    background: $white-0;
    border-color: $white-2;
    color: $black-2;
    &:hover {
      background: $white-0;
      border-color: $white-2;
      color: $black-2;
    }
    &:active {
      background: $white-0;
      border-color: $white-2;
      color: $black-2;
    }
    &:focus {
      background: $white-0;
      border-color: $white-2;
      color: $black-2;
    }
  }
  .ant-btn-ghost {
    color: $black-2;
    border-color: $white-2;
    &.ant-btn-blue {
      background: transparent !important;
      color: $blue-0 !important;
      border-color: $dark-blue-0;
    }
  }
  .ant-btn-dangerous {
    color: $red-1;
    border-color: $red-1;
    &:hover {
      color: $red-1;
      border-color: $red-1;
    }
    &.ant-btn-primary {
      border-color: $red-1 !important;
      background: $red-1 !important;
      color: $white-0;
    }
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: $gray-3;
  }
  .ant-btn-default[disabled],
  .ant-btn-default[disabled]:hover,
  .ant-btn-default[disabled]:focus,
  .ant-btn-default[disabled]:active {
    color: $black-2;
    background: $white-0;
    border: 1px solid $white-2;
    opacity: 0.5;
  }
  .ant-btn-green {
    background: $green-6 !important;
    border-color: $green-6 !important;
  }
  .ant-btn-red {
    background: $red-2 !important;
    border-color: $red-2 !important;
  }
  .ant-btn-dark-red {
    background: $red-5 !important;
    border-color: $red-5 !important;
    &:hover {
      background: $red-5 !important;
      border-color: $red-5 !important;
    }
    &:active {
      background: $red-5 !important;
      border-color: $red-5 !important;
    }
    &:focus {
      background: $red-5 !important;
      border-color: $red-5 !important;
    }
  }
  .ant-btn-dark-red1 {
    background: $red-10 !important;
    border-color: $red-10 !important;
    &:hover {
      background: $red-10 !important;
      border-color: $red-10 !important;
    }
    &:active {
      background: $red-10 !important;
      border-color: $red-10 !important;
    }
    &:focus {
      background: $red-10 !important;
      border-color: $red-10 !important;
    }
  }
  .ant-btn-gray {
    background: $gray-4 !important;
    border-color: $gray-4 !important;
    color: $black-3 !important;
  }
}
.wrapper {
  &.iconOnly {
    :global {
      span:not(.anticon) {
        display: none;
      }
    }
  }
  &.isLink {
    border: 0;
    background: transparent !important;
    color: $primary-0;
    box-shadow: none;
    text-shadow: none;
    width: auto;
    &:after {
      display: none;
    }
    &:global(.ant-btn-blue) {
      color: $blue-0;
    }
    &:global(.ant-btn-dark-red) {
      color: $red-5;
    }
  }
  &.largeIcon {
    :global {
      .anticon {
        font-size: 21px;
      }
    }
  }
  &.blueHover {
    &:hover {
      border-color: $blue-0;
      box-shadow: $blue-1-o-1-box-shadow;
    }
  }
}
