@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.paymentSourceTab {
  padding: 24px;
  background: $light-blue-0;
}
.deleteWarningModal {
  max-width: 423px;
  :global {
    .ant-modal-confirm-btns {
      .ant-btn.ant-btn-primary {
        background: #dd2a2a;
        border-color: #dd2a2a;
      }
    }
    .ant-modal-confirm-body {
      .anticon {
        color: #f5222d;
      }
    }
  }
}
.wrapper {
  position: relative;
}
.mainLoading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffffff80;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
