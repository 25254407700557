@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.tabContentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  :global {
    .ant-input-affix-wrapper {
      max-width: 256px;
    }
  }
}
.pagination {
  margin-top: 16px;
}
.highlightRow {
  animation: highlightRow 6s 1;
}
@keyframes highlightRow {
  0%,
  99.95% {
    background-color: $light-blue-0;
  }
  100% {
    background-color: $white-0;
  }
}
