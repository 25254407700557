@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.header {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(24, 144, 255, 0.5);
  display: flex;
  flex-wrap: wrap;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
    padding-right: 16px;
    color: $black-5;
    min-width: 345px;
    // max-width: 345px;
  }
  > div {
    margin-left: auto;
    display: flex;
    > * {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}
.content {
  padding: 18px 20px;
  .information {
    display: flex;
    margin-bottom: 24px;
    > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $black-1;
      padding-right: 10px;
      &:first-child {
        flex: 1;
        margin-right: 30px;
      }
      &:nth-child(2) {
        min-width: 150px;
      }
      &:nth-child(3) {
        min-width: 100px;
      }
      > span {
        font-size: 16px;
      }
    }
  }
}
.wrapper {
  padding: 32px 38px;
  background: rgba(24, 144, 255, 0.1);
  border-radius: 2px;
}
.checklistTitle {
  margin-bottom: 0;
}
.checklists {
  :global {
    .dnd-card {
      .dnd-handler {
        .anticon {
          color: transparent;
        }
      }
      &:hover {
        .dnd-handler {
          .anticon {
            color: black;
          }
        }
      }
    }
  }
}
.reminderLastChange {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8e8e8e;
  margin-top: 2px;
}

@media screen and (max-width: 1400px) {
  .header {
    h2 {
      min-width: 100%;
      max-width: 100%;
      + div {
        min-width: 100%;
        max-width: 100%;
        margin-top: 8px;
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }
  }
}
@media screen and (max-width: 1057px) {
  .header {
    h2 {
      + div {
        justify-content: flex-start;
        flex-wrap: wrap;
        > * {
          margin-top: 8px;
        }
      }
    }
  }
}
