@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  :global {
    .ant-tabs {
      .ant-tabs-nav {
        padding-right: 232px;
      }
    }
  }
  &.isNotFound {
    :global {
      .ant-tabs-nav-list {
        visibility: hidden !important;
      }
    }
  }
}
.mainContentTab {
  background: $white-0;
  padding: $space;
  :global {
    .ant-table {
      .ant-table-thead {
        > tr {
          > th {
            &:first-child {
              padding: 16px 8px;
              text-align: left;
            }
          }
        }
      }
      .ant-table-tbody {
        > tr {
          > td {
            > span {
              max-width: 150px;
            }
          }
        }
      }
      colgroup {
        col {
          &:first-child {
            width: 22px !important;
          }
        }
      }
    }
    .ant-table-cell.ant-table-cell-scrollbar {
      display: none;
    }
  }
  &.isNotFound {
    background: transparent;
  }
}
.tabContentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  gap: 20px;
  :global {
    .ant-input-affix-wrapper {
      max-width: 256px;
    }
  }
  .filterDropdown {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 24px;
    :global {
      .ant-select {
        width: 160px;
      }
    }
    .selectWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
.pagination {
  margin-top: 16px;
}
