@import 'src/assets/scss/base/colors';

.wrapper {
  padding: 40px 40px 58px 40px;
  background: $gray-9;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  &.isWhiteBg {
    background: $white-0;
  }
}
.header {
  color: $primary-1;
  font-size: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .emptyIcon {
    width: 51px;
    height: 51px;
    margin-right: 8px;
  }
  > span {
    cursor: pointer;
  }
}
.publicCard {
  width: calc(100vw - 320px);
  max-width: 930px;
  margin: 0 auto !important;
  background: $white-0;
  padding: 40px 40px 40px 64px;
}
.footer {
  font-size: 12px;
  color: $primary-1;
  line-height: 20px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 18px auto;
  > span {
    font-size: 16px;
    margin-right: 5px;
    position: relative;
    top: -1px;
  }
}
.logo {
  margin-right: 20px;
  cursor: pointer;
}
@media print {
  .wrapper {
    padding: 0;
  }
  .publicCard {
    display: none !important;
    &.printCard {
      display: block !important;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }
}
