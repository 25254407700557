@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';
@import 'src/assets/scss/base/mixin';

.wrapper {
  .mainContent {
    min-height: calc(100vh - 256px);
    padding: 40px 40px 40px 55px;
    display: flex;
    position: relative;
    > div {
      &:first-child {
        flex: 1;
      }
    }
    &.isEdit {
      padding-left: 36.5px;
    }
  }
}
.headerTitle {
  display: flex;
  align-items: center;
  background: $white-0;
  padding: 20px 20px 10px 20px;
  .avatar {
    margin-right: 16px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-bottom: 0;
    :global {
      .anticon {
        font-size: 22px;
      }
    }
  }
}
.informationSection {
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - 305px);
  overflow: auto;
  margin-bottom: 50px;
  .profileAvatar {
    margin-right: 47px;
    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 0;
      :global {
        .anticon {
          font-size: 22px;
        }
      }
    }
  }
  .informationInner {
    max-width: 530px;
    min-width: 530px;
    > div {
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;
      > div {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        flex-direction: column;
        &:first-child {
          max-width: 300px;
          min-width: 300px;
        }
        > span {
          color: $black-2;
          &:first-child {
            font-size: 16px;
            color: $black-1;
            margin-bottom: 5px;
            font-weight: bold;
          }
        }
        &.mergedColumn {
          display: flex;
          flex-direction: row;
          > div {
            display: flex;
            flex-direction: column;
            &:first-child {
              min-width: 50px;
              margin-right: 40px;
            }
            > span {
              &:first-child {
                font-weight: bold;
              }
            }
          }
        }
      }
      &:first-child {
        > div {
          > span {
            color: $primary-0;
          }
        }
      }
    }
  }
}
.statusRow {
  margin-bottom: 0;
  max-width: 100% !important;
  > div {
    &:first-child {
      max-width: 100%;
      min-width: 100%;
    }
    > span {
      &:first-child {
        font-size: 16px;
        color: $black-1;
        margin-bottom: 5px;
        display: inline-block;
        font-weight: bold;
      }
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        position: relative;
        > span {
          &:nth-child(2) {
            position: absolute;
            width: 500px;
            bottom: -22px;
          }
        }
      }
    }
    > button {
      margin-top: 16px;
    }
  }
}
.resendInviteBtn {
  &.isLoading {
  }
}
.deactivateBtn {
}
.activateBtn {
}
.actions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(196, 196, 196, 0.3);
  border-radius: 0px 0px 10px 10px;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  min-height: 72px;
  button {
    width: 94px;
    margin-right: 18px;
    &:last-child {
      margin-right: 0;
    }
  }
  :global {
    .ant-btn-ghost {
      background: white;
    }
  }
}
.editForm {
  .form {
    display: flex;
    flex-direction: row;
    .formInner {
      display: flex;
      flex-wrap: wrap;
      width: 680px;
      > div {
        display: flex;
        width: calc(50% - 20px);
        &:nth-child(2n + 1) {
          margin-right: 40px;
        }
      }
      :global {
        .ant-col.ant-form-item-label {
          font-weight: bold;
        }
        .security-field-wrapper {
          > div {
            > span {
              &:nth-child(1) {
                font-size: 14px;
                color: #262626;
                margin-bottom: 5px;
                font-weight: bold;
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .customItem {
      display: flex;
      :global {
        .ant-row.ant-form-item {
          &:first-child {
            width: 50px;
            margin-right: 40px;
            & + div {
              flex: 1;
            }
          }
        }
      }
    }
    :global {
      .ant-profile-pic-avatar {
        min-width: 110px;
        margin-right: 32px;
        > div,
        > img {
          width: 80px;
          height: 80px;
        }
        > div {
          .anticon {
            font-size: 20px;
          }
        }
      }
    }
  }
}
.teammateDetailWarningModal {
  max-width: 443px;
  :global {
    .ant-modal-confirm-content {
      margin-right: 0 !important;
      color: $black-2;
    }
    .ant-modal-body {
      padding: 32px;
    }
    .ant-modal-confirm-title {
      color: $black-1;
    }
  }
}
.statusButton {
  position: absolute !important;
  bottom: 16px;
  left: 16px;
  z-index: 1;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.requiredMark {
  @include required-mark;
}
.informationRestFields {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0 !important;
  > * {
    &:nth-child(2n + 1) {
      min-width: 300px;
      max-width: 300px;
    }
    margin-bottom: 24px;
  }
  :global {
    .security-field-wrapper {
      > div {
        > span {
          &:nth-child(1) {
            font-size: 16px;
            color: #262626;
            margin-bottom: 5px;
            font-weight: bold;
            display: inline-block;
          }
        }
      }
    }
  }
}
.shouldUpdateItem {
  margin-bottom: 0 !important;
}
