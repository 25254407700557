.wrapper {
  &.isNotFound {
    > div {
      min-height: 324px;
    }
  }
  &:not(.isNotFound) {
    > div {
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      > div {
        border-bottom: 1px solid #f7f7f7;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .bottomMark {
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
.notFound {
  margin-top: 32px;
}
