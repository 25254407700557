@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-right: 20px;
  .content {
    color: $black-7;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    b {
      font-weight: 600;
      line-height: 22px;
    }
  }
  :global {
    .anticon {
      margin-right: 9px;
      color: $yellow-1;
    }
  }
}
