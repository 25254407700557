@import 'src/assets/scss/base/mixin';

.wrapper {
  padding: 12px 16px;
  position: relative;
  cursor: pointer;
  > p {
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: #9b9b9b;
    margin: 0;
    padding-right: 16px;
    @include ellipsisLongText();
  }
  .startOrder {
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
  }
  // &:not(.readNotification):not(.hasStartOrder):hover {
  &:hover {
    background: #fafafa;
    // .title {
    //   > div {
    //     > h3 {
    //       font-weight: 700;
    //     }
    //   }
    // }
  }
  &.hasStartOrder {
    &:hover {
      // background: #fafafa;
      .startOrder {
        display: flex;
      }
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  > div {
    flex: 1;
    min-width: 0px;
    margin-right: 10px;
    > h3 {
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: black;
      margin: 0 0 4px 0;
      @include ellipsisLongText();
      width: 100%;
    }
    & + span {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #b8b8b8;
      display: inline-block;
      padding-top: 3px;
    }
  }
}
.startOrder {
  display: none;
  width: 115px;
  align-items: center;
  justify-content: center;
  background: rgba(250, 250, 250, 0.9);
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.05);
  cursor: default;
  :global {
    .ant-btn {
      padding: 0 6px;
    }
  }
}
