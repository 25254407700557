@import '../style.module';
.isSubmitted {
  .heading {
    text-align: center;
  }
  .bottomButton {
    margin-bottom: 30px;
    margin-top: 10px;
  }
}
