@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.treeTransfer {
  :global {
    .ant-tree-title {
      > div {
        > div {
          &:first-child {
            padding-left: $semi-small-space-horizontal;
          }
        }
      }
    }
    .ant-radio-group {
      position: relative;
      z-index: 3;
    }
    .ant-tree-list {
      height: 560px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  > div {
    &:first-child {
      border-right: 0;
      border-radius: 2px 0 0 2px;
    }
    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }
}
.treeHeader {
  padding-bottom: $small-space-vertical;
  border-bottom: 1px solid $white-2;
  > div {
    &:first-child {
      margin: $space-vertical $space-horizontal;
      width: 270px;
      margin-bottom: $space-vertical;
    }
  }
  &.headerRight {
    padding-left: 20px;
    position: relative;
    > div {
      margin-left: $space-horizontal;
    }
  }
}
.nodeCloseIcon {
  color: $blue-0;
  font-size: $semi-large-fz;
  margin-right: 5px;
  position: relative;
  top: 1px;
}
.selectedFieldsCount {
  color: $gray-3;
  position: absolute;
  top: 4px;
  right: $space-horizontal;
  font-style: italic;
}
