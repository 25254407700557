@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';
@import 'src/assets/scss/base/mixin';

.hide {
  display: none !important;
}
.fake {
  visibility: hidden !important;
}
.formItem {
  &.fullWidth {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  &.addressField {
    max-width: 100% !important;
    min-width: calc(100% - 200px) !important;
    margin-right: 40px;
  }
  &.aptField {
    max-width: 160px !important;
    min-width: 160px !important;
  }
  &.addressGroupField {
    max-width: calc(25% - 30px) !important;
    min-width: calc(25% - 30px) !important;
  }
  &.half {
    max-width: calc(50% - 20px) !important;
    min-width: calc(50% - 20px) !important;
  }
  :global {
    .ant-checkbox-disabled + span {
      color: $primary-0;
      cursor: default;
    }
  }
}
.fileField {
  min-width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 0;
  .uploadBtn {
    position: absolute;
    right: 0;
    top: -1px;
    :global {
      .anticon {
        font-size: 12px;
      }
    }
  }
  :global {
    .ant-form-item-label {
      label {
        width: 100%;
        padding-right: 100px;
        @include ellipsisLongText();
      }
    }
    .ant-form-item-control-input {
      align-items: flex-start;
    }
    .ant-form-item-control-input,
    .ant-col.ant-form-item-control {
      position: static;
    }
    .ant-form-item-control-input-content {
      > span {
        display: flex;
        flex-wrap: wrap;
        > div {
          width: 100%;
        }
      }
    }
    .ant-upload-list {
      width: 100%;
    }
    .ant-upload-list-item {
      margin-top: 0;
    }
    .ant-upload-list-text-container {
      margin-bottom: 3px;
    }
    .ant-upload-list-item-name {
      flex: none;
      cursor: pointer;
      width: auto;
      color: $blue-0;
      flex: 1;
      overflow: hidden;
    }
    .ant-upload-list-item-card-actions {
      margin-left: auto;
    }
    .ant-upload-list-item-card-actions-btn,
    .anticon {
      opacity: 1 !important;
      font-size: 16px;
      position: static !important;
    }
    .ant-upload-span {
      height: 22px;
    }
    .ant-upload-list-item-info,
    .ant-upload-list-item-card-actions-btn {
      background: transparent !important;
    }
  }
}
.fileEmptyText {
  font-size: 14px;
  line-height: 22px;
  color: $gray-3;
  :global {
    .anticon {
      font-size: 16px;
      margin-right: 4px;
    }
  }
}
.fileDisable {
  background: $white-4;
  border: 1px solid $white-2;
  padding: 5px 12px;
  color: $gray-3;
  border-radius: 2px;
  line-height: 20px;
}
