@import 'src/assets/scss/base/constants';
@import '../style.module';

.wrapper {
  position: relative;
  margin-bottom: -30px;
  :global {
    .ant-space-item {
      &:nth-child(2),
      &:nth-child(3) {
        margin: 0 !important;
      }
    }
  }
}
.forgotPassword {
  position: relative;
  top: -$space-vertical;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    cursor: pointer;
  }
}
.keepMeSignedIn {
  // position: relative;
  // top: -10px;
  margin-bottom: 0;
}
.dontHaveAccount {
  display: flex;
  font-size: 16px;
  color: $primary-1;
  line-height: 22px;
  margin-top: 48px;
  p {
    font-weight: 600;
    text-decoration: underline;
  }
  .mobileApp {
    margin-right: 28px;
    display: flex;
    align-items: flex-end;
  }
  .howToDownload {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      > * {
        &:not(:last-child) {
          margin-right: 16px;
        }
        margin-top: 16px;
      }
    }
    > p {
      margin-bottom: 0;
    }
  }
}
.heading {
  margin-bottom: 16px;
}
.errorMessage {
  color: $red-6;
  margin-bottom: $space;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  text-align: center;
}
.bottomButton {
  margin-top: 24px;
}
@media screen and (max-width: 520px) {
  .dontHaveAccount {
    .howToDownload {
      > div {
        flex-wrap: wrap;
        > * {
          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
