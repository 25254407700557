@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  display: flex;
  .mainContent {
    margin: 0;
    display: flex;
    border-radius: 10px 0 0 10px;
    min-height: 1px;
    padding: 20px 45px 20px 40px;
    flex: 1;
    max-width: calc(100% - 325px);
    > div {
      &:first-child {
        flex: 1;
      }
    }
  }
  .leftContent {
    flex: 1;
    margin-right: 1px;
    max-width: 100%;
  }
  .activity {
    width: 325px;
    max-width: 325px;
    min-width: 325px;
  }
  &.isActivityCollapsed {
    .activity {
      width: 80px;
      max-width: 80px;
      min-width: 80px;
    }
    .mainContent {
      max-width: calc(100% - 80px);
    }
  }
  :global {
    .ant-form-item-label {
      > label {
        font-weight: 500;
      }
    }
  }
}
.header {
  font-weight: 600;
  font-size: 20px;
  background: $white-0;
  padding: 20px 20px 10px 20px;
  display: flex;
  :global {
    .ant-page-header-heading-left {
      width: 100%;
      .ant-page-header-heading-title {
        width: 100%;
        > span {
          width: 100%;
          display: flex;
        }
      }
    }
  }
  .primaryContact {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.headerTitle {
  display: flex;
  align-items: flex-start;
  .avatar {
    margin-right: 24px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    :global {
      .anticon {
        font-size: 22px;
      }
    }
  }
  > span {
    font-size: 24px;
    line-height: 32px;
  }
  .clientInfo {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    > div {
      margin-top: 6px;
      font-size: 12px;
      color: $black-6;
      line-height: 14px;
      > span {
        font-weight: 300;
        &:first-child {
          font-weight: 500;
        }
        &:last-child {
          font-weight: 600;
        }
      }
    }
    .clientInfoTop {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  :global {
    .ant-profile-pic-avatar {
      position: relative;
      min-width: 100px;
      .ant-upload {
        > img,
        > input + div {
          cursor: pointer;
          width: 100px;
          height: 100px;
          margin-bottom: 0;
        }
        > input + span {
          top: -8px;
          right: 12px;
        }
      }
    }
  }
}
.warningModal {
  max-width: 443px;
  :global {
    .ant-modal-confirm-content {
      margin-right: 0 !important;
      color: $black-2;
    }
    .ant-modal-body {
      padding: 32px;
    }
    .ant-modal-confirm-title {
      color: $black-1;
    }
  }
}
.tabs {
  :global {
    .ant-tabs-nav {
      padding: 0;
      .ant-tabs-nav-wrap {
        max-width: calc(100% - 170px);
      }
    }
    .ant-tabs-content-holder {
      padding: 24px 0;
    }
  }
}
.contentWrapper {
  padding: 20px;
  display: flex;
}
.tabHeaderButton {
  position: absolute;
  right: 45px;
  top: 28px;
}
.newNameInput {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0;
  font-size: 20px;
  &:focus {
    box-shadow: none !important;
  }
  &::placeholder {
    color: $black-0 !important;
  }
}
.actionBlockMenu {
  margin-top: 24px;
  min-width: 153px;
  :global {
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background: #e8f4ff;
    }
  }
}
.deleteClientWarningModal {
  max-width: 423px;
  :global {
    .ant-modal-confirm-btns {
      .ant-btn.ant-btn-primary {
        background: #dd2a2a;
        border-color: #dd2a2a;
      }
    }
    .ant-modal-confirm-body {
      .anticon {
        color: #f5222d;
      }
    }
  }
}
