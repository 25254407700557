@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';
@import 'src/assets/scss/base/mixin';

.treeWrapper {
  :global {
    .ant-tree-title {
      > div {
        > div {
          &:first-child {
            padding-left: $semi-small-space-horizontal;
          }
        }
      }
    }
    .ant-tree-list {
      max-height: 560px;
      overflow-x: hidden;
      overflow-y: auto;
      height: auto;
    }
    .ant-transfer-list {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: auto;
    }
    .ant-tree-treenode {
      .ant-tree-title {
        > div {
          > div {
            max-width: 530px;
            width: calc(100% - 400px);
            display: flex;
            align-items: center;
            &:first-child {
              max-width: 384px;
              min-width: 384px;
              padding-right: 24px;
            }
          }
        }
      }
    }
  }
  width: 100%;
  > div {
    &:first-child {
      > h2 {
        padding-left: 0 !important;
      }
    }
  }
}
.headerRow {
  > div {
    max-width: 100% !important;
    min-width: auto !important;
    font-weight: bold;
    &:first-child {
      max-width: 400px !important;
      min-width: 400px !important;
    }
  }
}
.treeHeader {
  > div {
    margin-left: 12px !important;
  }
}
