@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

:global {
  .ant-timeline-item-last {
    position: relative;
    padding-bottom: 0;
    &:before {
      content: ' ';
      width: 2px;
      min-height: 10px;
      border-right: 2px solid $gray-7;
      position: absolute;
      left: 4px;
      bottom: 16px;
      top: 0;
    }
  }
}
.wrapper {
}
