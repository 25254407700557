@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

:global {
  .ant-transfer {
    color: $black-2;
    display: flex;
    .ant-transfer-list {
      min-width: 50%;
      max-width: 50%;
      flex: 1;
      .ant-transfer-list-header {
        display: none;
      }
      .ant-transfer-list-body {
        padding-right: 15px;
      }
    }
    .ant-transfer-operation {
      display: none;
    }
  }
  .ant-tree {
    background: transparent !important;
    .ant-tree-treenode {
      min-height: 40px;
      align-items: center;
      position: relative;
      padding-left: $space-horizontal;
      margin-bottom: $small-space-vertical;
      .ant-tree-checkbox {
        margin: 0;
      }
      &.filter-node {
        background: $white-3;
        &.ant-tree-treenode-leaf-last {
          margin-bottom: 0;
        }
      }
      .ant-tree-title {
        font-weight: normal !important;
        color: $black-2 !important;
      }
    }
    .ant-tree-node-content-wrapper {
      background-color: transparent !important;
      padding: 0;
      cursor: default;
    }
    .ant-tree-switcher {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0px;
      z-index: 2;
    }
    .ant-tree-switcher_close {
      transform: rotate(-90deg);
    }
  }
}
