@import 'src/assets/scss/base/colors';

.action {
  display: flex;
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  margin: auto;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 16px;
  }
  :global {
    .anticon {
      font-size: 24px;
      cursor: pointer;
    }
    .anticon-edit {
      color: #494949;
    }
    .anticon-delete {
      color: $red-9;
    }
  }
}
.view {
  display: flex;
  > label {
    margin-right: 20px;
  }
}
.footerAction {
  :global {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-start;
      > *:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
