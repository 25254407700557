@import 'src/assets/scss/base/colors';

.wrapper {
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #222222;
    margin-bottom: 4px;
  }
  .companyName {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: $blue-0;
    display: inline-block;
    margin-bottom: 16px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      margin-right: 40px;
      &:nth-child(1) {
        width: 150px;
      }
      &:last-child {
        margin-right: 0;
        flex: 1;
      }
      > span {
        &:nth-child(1) {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #262626;
          display: inline-block;
          margin-bottom: 5px;
        }
        &:nth-child(2) {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #595959;
        }
      }
    }
    &.list3Columns {
      > li {
        &:nth-child(2) {
          width: 170px;
        }
      }
    }
  }
}
.orderStatus {
  margin-bottom: 16px;
}
.link {
  color: #1890ff !important;
  cursor: pointer;
}
.fileList {
  display: flex;
  flex-direction: column;
}
.paymentDisplay {
  display: flex;
  flex-direction: column;
}
