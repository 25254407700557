.line {
  max-width: 100% !important;
  min-width: 100% !important;
  > div {
    max-width: calc(25% - 30px) !important;
    min-width: calc(25% - 30px) !important;
  }
}
div.fuel_card_form {
  justify-content: normal;
  column-gap: 40px;
  .field_one_fourth {
    min-width: calc(25% - 30px);
    max-width: calc(25% - 30px);
  }
}
.billingAddress {
  display: flex;
  flex-direction: column;
  .title {
    border-bottom: 1px solid rgba(24, 144, 255, 0.5);
    padding-bottom: 14px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #2c2c2c;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .addressForm {
    min-width: 100% !important;
    max-width: 100% !important;
    justify-content: flex-start;
    > div {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        margin-right: 40px;
      }
    }
  }
}
