@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  display: flex;
  &.inSection {
    position: absolute;
    top: 32px;
    right: 38px;
  }
}
.statusDropdown {
  :global {
    .anticon.anticon-down {
      display: none;
    }
  }
}
.statusDropdownMenu {
  background-color: transparent !important;
  box-shadow: none;
  padding: 0;
  li {
    background-color: transparent !important;
    padding: 0;
    > a {
      padding-top: 0;
    }
  }
}
