@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.header {
  > div {
    &:first-child {
      align-items: center;
    }
  }
}
