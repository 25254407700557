.wrapper {
  color: #333333;
  text-align: center;
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    margin: 0;
  }
  p {
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    margin: 0;
  }
  .link {
    margin-top: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    > div > span {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  :global {
    .ant-space-item {
      margin: 0 !important;
    }
  }
}
