@import 'src/assets/scss/base/constants';
@import '../style.module';

.wrapper {
  text-align: center;
  .heading {
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 60px;
  }
  .successImg {
    margin-bottom: 42px;
    width: 161px;
    height: 161px;
  }
  .logo {
    width: 51px;
    height: 51px;
    margin-right: $semi-small-space-horizontal;
  }
  p {
    line-height: 24px;
    > span {
      color: $blue-0;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
    }
  }
}
