.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 36px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    color: #494949;
  }
  textarea {
    min-height: 90px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}
