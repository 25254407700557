@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  :global {
    .ant-tabs {
      .ant-tabs-nav {
        padding-right: 290px;
      }
    }
  }
}
.tabHeaderButton {
  position: absolute;
  right: $space-horizontal;
  top: 17px;
}
.notOrderTypesFound {
  width: 100%;
  min-height: calc(100vh - 176px);
  padding-top: 80px;
  :global {
    .ant-empty-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      h3 {
        margin-bottom: 32px;
        font-size: 20px;
      }
    }
  }
}
.allOrderTypesTab {
  background: $white-0;
  padding: $space;
  :global {
    .ant-table {
      .ant-table-thead {
        > tr {
          > th {
            &:first-child {
              padding: 16px 8px;
              text-align: left;
            }
            &:nth-child(7) {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
.tabContentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  :global {
    .ant-input-affix-wrapper {
      max-width: 256px;
    }
  }
  .actions {
    display: flex;
    :global {
      .ant-btn {
        max-width: 88px;
      }
    }
    > button {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 24px 0 16px;
      height: 32px;
      width: 1px;
      border-right: 1px solid $gray-0;
    }
  }
}
.pagination {
  margin-top: 16px;
}
.mainContent {
  &.hideTabs {
    .tabHeaderButton {
      display: none !important;
    }
    :global {
      .ant-tabs {
        display: none !important;
      }
    }
  }
}
