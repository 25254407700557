@import 'src/assets/scss/base/colors';

.wrapper {
  width: 100%;
  > h2 {
    margin-bottom: 16px;
  }
  > p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  &.isEditing {
    .actionButtons {
      display: none;
    }
  }
  &.viewMode {
    .formListSection {
      padding-bottom: 0 !important;
    }
    margin-bottom: 0 !important;
    .viewHeader {
      > div {
        font-weight: bold;
      }
    }
  }
}
.newFormBlock {
  display: flex;
  flex-direction: row;
  > div {
    max-width: 33.3%;
    min-width: 33.3%;
    padding-right: 40px;
    &:last-child {
      padding-right: 0;
    }
  }
  &.editBlockAtView {
    justify-content: flex-start;
    > div {
      max-width: 33.3%;
      min-width: 33.3%;
      padding-right: 40px;
      &:last-child {
        padding-right: 0;
      }
    }
    :global {
      .ant-form-item {
        margin-bottom: 13px;
      }
    }
  }
}
.formListSection {
  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    > button {
      width: 80px !important;
      font-weight: bold;
    }
    > div {
      button {
        margin-top: 8px;
      }
    }
  }
}
.plusIcon {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: -1px;
}
.formBlockHeader {
  justify-content: flex-end;
  order: 2;
}
.viewHeader {
  display: flex;
  > div {
    color: #262626;
    font-size: 14px;
    line-height: 22px;
    max-width: 33.3%;
    min-width: 33.3%;
    padding-right: 40px;
    &:last-child {
      padding-right: 0;
    }
  }
}
.viewBlock {
  position: relative;
  display: flex;
  > div {
    padding: 5px 12px;
    font-size: 14px;
    line-height: 22px;
    color: #090909;
    max-width: 33.3%;
    min-width: 33.3%;
    padding-right: 40px;
    &:last-child {
      padding-right: 0;
    }
    &:nth-child(3) {
      padding-right: 70px;
    }
  }
  .actionBlock {
    display: none;
  }
  &.canEdit {
    &:hover {
      background: rgba(24, 144, 255, 0.1);
      .actionBlock {
        display: flex;
      }
    }
  }
}
.actionBlock {
  display: flex;
  position: absolute;
  right: 10px;
  top: 7px;
  > * {
    margin-right: 12px;
    font-size: 18px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
      color: $red-9;
    }
  }
}
.confirmationModal {
  max-width: 423px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  > * {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.newBlock {
  .actionBlock {
    top: 36px;
  }
}
