@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

:global {
  .ant-tabs {
    .ant-tabs-nav {
      background: $white-0;
      padding: 0 $space-horizontal;
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }
    .ant-tabs-content-holder {
      padding: $space-horizontal;
    }
  }
  .ant-tabs-tab {
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        font-weight: 600;
      }
    }
  }
}
.spaceTop {
  :global {
    .ant-tabs-nav {
      padding-top: 22px;
    }
  }
}
