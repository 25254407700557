$sidebar-width: 200px;
$sidebar-collapsed-width: 63px;
$chat-width: 380px;
$chat-collapsed-width: 80px;
$top-bar-height: 64px;
$page-header-height: 56px;
$page-wrapper-padding: 32px;
$page-content-additional-margin-bottom: 24px; // because page-wrapper-padding-bottom is 16px, and page-content should margin-bottom: 40px;
$page-content-minus-height: $top-bar-height + $page-header-height +
  $page-wrapper-padding + $page-content-additional-margin-bottom;
$large-space-vertical: 32px;
$space-vertical: 16px;
$small-space-vertical: 8px;
$semi-small-space-horizontal: 8px;
$small-space-horizontal: 16px;
$space-horizontal: 32px;
$small-fz: 12px;
$normal-fz: 14px;
$semi-large-fz: 16px;
$large-fz: 20px;
$border-radius: 8px;
$normal-icon-width: 14px;
$normal-icon-height: 14px;
$space: 24px;
