.wrapper {
  background: #f5f5f5;
  border-radius: 0px 0px 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 33px;
  min-height: 33px;
  > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1890ff;
    cursor: pointer;
  }
}
