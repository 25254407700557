@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
}
.addColumnBlock {
  color: $blue-0;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  background: $white-0;
  border-radius: 2px 2px 0px 0px;
  padding: 16px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.form {
  color: red;
  padding: 8px;
  background: white;
  .bottomButton {
    margin-top: 16px;
    :global {
      .ant-btn > .anticon + span {
        margin-left: 4px;
      }
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;
        > button {
          padding-left: 8px;
          padding-right: 8px;
          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
    }
  }
  :global {
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
    .ant-input {
      padding: 8px 8px 35px 8px;
    }
  }
}
