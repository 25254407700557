@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.color {
  &.isGreen {
    color: $green-6;
  }
  &.isRed {
    color: $red-2;
  }
  &.isWarning {
    color: $orange-6;
  }
  &.isBlue {
    color: $blue-0;
  }
}
.notInitDataContent {
  font-size: 20px;
  color: $black-1;
  font-weight: 600;
  text-align: center;
  :global {
    .anticon {
      color: $black-0;
      font-size: 64px;
      padding: 8px;
    }
  }
  p {
    margin-top: 16px;
    margin-bottom: 0;
  }
}
