@import 'src/assets/scss/base/mixin';

.wrapper {
  border-top: 3px solid rgba(24, 144, 255, 0.5) !important;
  :global {
    .ant-collapse-item {
      border: 0;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04),
        0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    }
    .ant-collapse-header {
      background-color: white !important;
      padding-right: 8px !important;
      height: 76px !important;
      > .anticon {
        display: none !important;
        & + div {
          padding-left: 24px !important;
          padding-right: 24px !important;
        }
      }
      .ant-collapse-extra {
        width: auto !important;
      }
      > *:nth-child(2) {
        width: 100%;
      }
    }
    .ant-collapse-content {
      background: white !important;
    }
  }
  &.isExpanded {
    :global {
      .ant-collapse-header {
        position: relative;
        &:after {
          content: ' ';
          display: block;
          position: absolute;
          left: 12px;
          right: 12px;
          bottom: 0;
          height: 1px;
          border-bottom: 1px solid rgba(24, 144, 255, 0.5);
        }
      }
    }
  }
  &.isEdit {
  }
}
.header {
  display: flex;
  gap: 16px;
  align-items: center;
  > h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #494949;
    margin: 0;
    & + span {
      font-weight: normal;
    }
  }
}
.expiredDate {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: auto;
  margin-right: 120px;
  min-width: 140px;
  > span {
    &:nth-child(1) {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #262626;
    }
    &:nth-child(2) {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #090909;
    }
  }
}
.headingLink {
  height: 22px;
  @include ellipsisLongText();
}
.hasFile {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #219653;
  margin-left: auto;
}
