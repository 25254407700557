.wrapper {
  .stepContent {
    min-height: 350px;
    margin-top: 30px;
    padding: 20px;
    .stepTitle {
      text-align: center;
      margin-bottom: 30px;
    }
    &.listInfo {
      > ul {
        padding: 0;
      }
    }
  }
  .dotNumberRow {
    display: flex;
    > * {
      padding: 10px 20px;
      width: 40%;
      text-align: left;
    }
    > :first-child {
      width: 60%;
    }
    &.companyName {
      > *:first-child {
        width: 100%;
      }
    }
    .dotNumberQue {
      text-align: center;
    }
  }
  .stepAction {
    justify-content: space-between;
    display: flex;
    flex-direction: row-reverse;
  }
}
