.footerAction {
  :global {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-start;
      > *:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
.wrapper {
  :global {
    .ant-input {
      max-width: 630px;
    }
  }
}
