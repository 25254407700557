@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';
@import 'src/assets/scss/base/mixin';

.pointer {
  cursor: pointer !important;
}
.textSmall {
  font-size: 12px !important;
}
.wrapper {
  background: $white-4;
  :global {
    .ant-tabs-nav {
      padding: 0;
      .ant-tabs-tab {
        background: $white-2;
        border-color: $white-4 !important;
        color: $gray-10;
        .ant-badge-count {
          margin-right: 10px;
        }
        &.ant-tabs-tab-active {
          background: $white-4;
          border-radius: 2px 2px 0px 0px;
        }
        &:not(.ant-tabs-tab-active) {
          .ant-badge-count {
            background: $gray-10;
            color: $white-0;
          }
        }
      }
    }
    .ant-tabs-content-holder {
      padding: 0;
    }
    .ant-input-affix-wrapper.ant-input-is-blue {
      background: $white-0 !important;
    }
    .ant-badge-count {
      min-width: 16px;
      height: 16px;
      font-size: 12px;
      box-shadow: none;
      line-height: 16px;
      border-radius: 10px;
    }
    .ant-badge-multiple-words {
      padding: 0 2px;
    }
  }
  .chatContent {
    background: $white-0;
  }
  &.isCollapse {
    :global {
      .ant-tabs-nav-wrap {
        background: $white-0;
      }
      .ant-tabs-tab {
        display: none;
        &.ant-tabs-tab-active {
          display: inline-flex;
          width: 71px;
          max-width: 100%;
          margin: 0 !important;
          padding-left: 0;
          padding-right: 0;
          justify-content: center;
        }
        .ant-badge {
          display: none;
        }
      }
      .ant-tabs-nav-operations {
        display: none !important;
      }
    }
    .searchBox {
      padding: 10px 8px;
      .icons {
        display: none;
      }
    }
    .startNewChat {
      justify-content: center;
      .closeStart {
        margin-right: 10px;
      }
      :global {
        .anticon {
          margin: 0;
        }
      }
    }
    .backIcon {
      display: none;
    }
    .tabs {
      &.isClientActive {
        :global {
          .ant-tabs-nav-wrap {
            justify-content: flex-end;
          }
        }
      }
      &.isCollapse.isChatClientView {
        :global {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              &:first-child {
                &.ant-tabs-tab-active {
                  font-size: 11px;
                }
              }
              &:nth-child(2) {
                &.ant-tabs-tab-active {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.backIcon {
  font-size: 24px;
  margin-right: 10px;
  color: $black-4;
  cursor: pointer;
}
.searchBox {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  min-height: 48px;
  .icons {
    margin-left: 10px;
  }
  .backBlock {
    display: flex;
    align-items: center;
    > span {
      cursor: pointer;
    }
  }
}
.chatContent {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 215px);
  overflow-y: auto;
  overflow-x: hidden;
  @include hideScrollBar();
}
.icons {
  display: flex;
  align-items: center;
  :global {
    .ant-dropdown-trigger {
      margin-right: 10px;
      cursor: pointer;
      line-height: 0;
      color: $black-0;
    }
    .anticon-last {
      margin-right: 0;
    }
  }
  .iconActive {
    color: $blue-0;
    .sortIcon {
      path {
        fill: $blue-0;
      }
    }
  }
}
.startNewChat {
  height: 64px;
  background: $white-0;
  font-size: 12px;
  color: $blue-0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 18px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border-top: 1px solid $white-5;
  > span {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.disabled {
      color: $gray-12;
      cursor: not-allowed;
    }
  }
  :global {
    .anticon {
      font-size: 24px;
      margin-left: 10px;
    }
  }
  &.isStart {
    justify-content: space-between;
    .closeStart {
      color: $red-9;
    }
  }
}
.newChatContent {
  background: $white-0;
  height: calc(100vh - 215px);
  display: flex;
  flex-direction: column;
  .existingContacts {
    min-height: 136px;
    overflow-x: hidden;
    overflow-y: auto;
    @include hideScrollBar();
    :global {
      .ant-checkbox {
        input {
          &:focus {
            + .ant-checkbox-inner {
              border-color: $white-2;
            }
            &:hover {
              + .ant-checkbox-inner {
                border-color: $blue-0;
              }
            }
          }
        }
      }
    }
  }
  .selectedContacts {
    max-height: calc(100% - 179px);
    > div {
      max-height: 100%;
      overflow: auto;
      @include hideScrollBar();
    }
  }
  .selectedLabel {
    border: 0;
    background: $white-4;
    margin-left: 0 !important;
    padding-left: 16px !important;
  }
  &.existingLength1 {
    .existingContacts {
      min-height: 68px;
    }
    .selectedContacts {
      max-height: calc(100% - 111px);
    }
  }
  &.existingLength0 {
    .existingContacts {
      min-height: auto;
    }
    .selectedContacts {
      max-height: calc(100% - 43px);
    }
  }
}
.chatDetail {
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  .detailContact {
    margin-left: 0;
    min-height: 65px;
    max-height: 65px;
    background: #eeeeee;
    padding-left: 16px;
  }
}
.detailContent {
  flex: 1;
  background: $white-0;
  min-height: 0px;
  .detailContentInner {
    max-height: 100%;
    overflow: auto;
    @include hideScrollBar();
  }
}
.messages {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.messageInput {
  position: relative;
  min-height: 64px;
  display: flex;
  background: $white-0;
  border-top: 1px solid $white-5;
  flex-wrap: wrap;
  padding: 17px 16px;
  &.hasFile {
    padding-top: 10px;
  }
  > div {
    flex: 1;
    margin: 0 10px;
  }
  .selectedAttachments {
    min-width: 100%;
    margin: 0 0 10px 0;
  }
  :global {
    .anticon {
      font-size: 24px;
      color: $blue-0;
      cursor: pointer;
      position: relative;
      top: 3px;
    }
    .ant-input {
      border: 0;
      background: $white-0 !important;
      box-shadow: none !important;
      position: relative;
      top: -2px;
    }
  }
}
.messageSendingWrapper {
  position: absolute;
  top: -40px;
  left: -6px;
}
.settingContentWrapper {
  > div > div {
    margin-top: 32px;
  }
  .settingMemberAction {
    margin-top: 0;
  }
}
.settingAvatars {
  display: flex;
  justify-content: center;
  .avatar {
    margin-left: -6px;
  }
  :global {
    .ant-avatar {
      border: 1px solid $white-0;
    }
  }
}
.settingNotification {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
  label {
    font-size: 16px;
    color: $black-1;
    display: flex;
    flex-direction: column;
    > span {
      font-size: 12px;
      color: $gray-12;
    }
  }
}
.settingMembers {
  h3 {
    font-size: 16px;
    color: $black-0;
    margin: 0 0 12px 16px;
  }
  :global {
    .ant-additional-action {
      position: absolute;
      top: 12px;
      right: 0;
    }
  }
}
.settingMemberAction {
  height: 64px;
  border-bottom: 1px solid $white-5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: $blue-0;
  }
  :global {
    .anticon {
      font-size: 24px;
    }
  }
}
.selectedAttachments {
  > div {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.filterSortOverlay {
  font-size: 12px;
  max-height: 500px;
  overflow: auto;
  padding-top: 0;
  :global {
    .ant-dropdown-menu-submenu-expand-icon {
      display: none;
    }
    .ant-dropdown-menu-submenu-title {
      cursor: default;
      background: transparent !important;
      font-size: 12px;
    }
  }
  > li {
    &:first-child {
      border-bottom: 1px solid $white-5;
      font-size: 12px;
      padding-top: 4px;
    }
  }
  &.sortOverlay {
    :global {
      .ant-radio-group.ant-radio-group-outline {
        display: flex;
        flex-direction: column;
        .ant-radio-wrapper {
          margin: 5px 0;
          &:nth-child(3) {
            position: relative;
            &:before,
            &:after {
              content: ' ';
              height: 1px;
              border-top: 1px solid $white-5;
              position: absolute;
              left: -12px;
              right: -24px;
            }
            &:before {
              top: -5px;
            }
            &:after {
              bottom: -5px;
            }
          }
        }
      }
    }
  }
  &.isClient {
    > li {
      &:first-child {
        border-bottom: 0;
        background: white;
        position: sticky;
        top: 0;
        z-index: 2;
      }
    }
  }
}
.filterLabelWrapper {
  &.isClient {
    display: flex;
    flex-direction: column;
  }
}
.filterClientSearch {
  margin-left: -12px;
  width: calc(100% + 36px);
  padding: 8px;
  background: $white-5;
  margin-top: 5px;
}
.searchFilterInput {
  background: $white-0 !important;
  input {
    background: $white-0 !important;
    box-shadow: none !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}
