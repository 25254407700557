@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #494949;
    margin-bottom: 16px;
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $black-5;
    margin-bottom: 8px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: calc(50% - 20px);
      min-width: calc(50% - 20px);
      margin-bottom: 24px;
      &:nth-child(2n + 1) {
        margin-right: 40px;
      }
      > span {
        &:nth-child(1) {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #262626;
          display: inline-block;
          margin-bottom: 5px;
        }
        &:nth-child(2) {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #595959;
        }
      }
    }
  }
}
.innerContent {
  padding-top: 24px;
  .section {
    &:last-child {
      border: 0;
      margin-bottom: 0;
    }
  }
}
.section {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 32px;
  padding-bottom: 8px;
}
.displayFileList {
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
}
