.container {
  width: 100%;
  > div {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.card {
  display: flex;
  align-items: flex-start;
}
.handler {
  cursor: move;
  margin-right: 16px;
}
.cardContent {
  flex: 1;
}
.wholeHandler {
  display: flex;
  width: 100%;
}
