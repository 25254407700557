@import 'src/assets/scss/base/colors';

.label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #020202;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.addressSection {
  justify-content: flex-start;
  > div {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      margin-right: 40px;
    }
  }
}
.cardError {
  .cardBlock {
    :global {
      .ant-input,
      .ant-picker {
        border-color: $red-9;
      }
    }
  }
}
.errorTitle {
  font-size: 16px;
  color: $red-9;
  display: flex;
  align-items: center;
  margin-left: 32px;
  gap: 4px;
  :global {
    .anticon {
      font-size: 24px;
      margin-right: 8px;
      display: inline-block;
    }
  }
}
