@import 'src/assets/scss/base/colors';

.breadcrumbItem {
  &.isBlue {
    color: $violet-4 !important;
    > a {
      color: $violet-4 !important;
    }
  }
  &:not(.isBlue) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: $black-0;
    cursor: default;
  }
}
