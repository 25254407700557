@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.header {
  margin-bottom: 28px;
}
.wrapper {
  position: relative;
}
.tempButtons {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  > * {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
.modalWrapper {
  :global {
    .ant-modal-header {
      display: none !important;
    }
    .ant-modal-body {
      > div {
        border-top: 0 !important;
      }
    }
  }
}
