.wrapper {
  background: linear-gradient(180deg, #2a345c 0%, #141d42 100%);
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.inner {
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  padding: 68px 28px 160px 28px;
  > p {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    margin: 0 0 25px 0;
  }
  > span {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
}
.logo {
  margin-bottom: 105px;
  min-width: 313px;
  min-height: 163px;
}
.mobileAppWrapper {
  background: #0f1633;
  padding: 24px 24px 34px 24px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  width: 100%;
  margin-top: 134px;
  align-items: center;
  > a {
    background: #eb5144;
    width: 100%;
    text-decoration: none !important;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
  }
}
