@import 'src/assets/scss/base/colors';

:global {
  .ant-tag {
    font-weight: normal;
    &.ant-tag-blue {
      border-color: $blue-1-o-1;
      color: $blue-0;
      background: $blue-1-o-2;
    }
    &.ant-tag-red {
      background: $red-3;
    }
  }
  .ant-tag-error {
    background: $red-8;
    color: $red-1;
    border-color: $red-7;
  }
}
.wrapper {
  &.large {
    padding: 5px 16px;
    font-size: 14px;
  }
  :global {
    .anticon {
      margin-right: 4px;
    }
  }
}
