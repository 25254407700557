@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.inner {
  text-align: center;
  width: 430px;
  max-width: 100%;
  padding: 0 12px;
  margin: 0 auto;
  img {
    max-width: 228px;
    max-height: 228px;
    border-radius: 50%;
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
.modalWrapper {
  :global {
    .ant-modal-body {
      padding: 40px 40px 48px 40px;
    }
  }
}
