@import 'src/assets/scss/base/colors';

:global {
  .ant-table {
    color: $black-2;
  }
  .ant-table-thead {
    > tr {
      > th {
        color: $black-1;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        padding: 15px 16px;
        &.ant-table-column-sort,
        &.ant-table-column-has-sorters:hover {
          background: $white-1;
        }
      }
    }
  }
  .ant-table-tbody {
    > tr {
      &.ant-table-row-selected {
        > td,
        &:hover > td {
          background: $white-1;
        }
      }
      > td {
        border-color: $gray-0;
        background: transparent;
        padding: 15px 16px;
        &.ant-table-cell-fix-left,
        &.ant-table-cell-fix-right {
          background: $white-0;
        }
      }
    }
  }
  .ant-table-container {
    > .ant-table-body {
      overflow: auto !important;
    }
    &:after,
    &:before {
      display: none !important;
    }
  }
}
.columnActionWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  span {
    color: $black-3;
    cursor: pointer;
    transform: rotate(-90deg);
    font-size: 18px;
  }
  :global {
    .ant-dropdown-trigger {
      display: flex;
      align-items: center;
    }
  }
}
.columnActionDropdown {
  top: -23px;
  min-width: 105px;
  margin-right: 20px;
  &.isEmpty {
    display: none;
  }
  &.isBorderLastItem {
    > li {
      &:last-child {
        position: relative;
        &:after {
          content: ' ';
          height: 1px;
          border-top: 1px solid $gray-0;
          position: absolute;
          display: block;
          left: 4px;
          right: 4px;
          top: 0;
        }
      }
    }
  }
  &.lastItemRed {
    > li {
      &:last-child {
        a,
        span {
          color: $red-5;
        }
      }
    }
  }
  &.lastItemBlue {
    > li {
      &:last-child {
        a,
        span {
          color: $blue-0;
        }
      }
    }
  }
  &.disabled {
    > li {
      cursor: default;
    }
  }
}
.wrapper {
  &.bordered {
    .columnActionWrapper {
      text-align: center;
    }
  }
  &.rowIsClick {
    :global {
      .ant-table-body {
        .ant-table-placeholder {
          td {
            cursor: default;
          }
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
  &.isScrollCSS {
    :global {
      .ant-table-content {
        overflow: auto;
      }
    }
  }
}
.columnActionOverlay {
  height: 0 !important;
}
