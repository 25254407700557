@import 'src/assets/scss/base/colors';

:global {
  .ant-checkbox-wrapper {
    display: flex;
    .ant-checkbox {
      top: 0;
      display: flex;
      align-items: center;
      &:after {
        display: none !important;
      }
      & + span {
        flex: 1;
        padding-left: 24px;
      }
    }
  }
}
