.wrapper {
  &.isViewDetail {
    :global {
      .ant-col.ant-form-item-control {
        .ant-col.ant-form-item-control {
          display: none !important;
        }
      }
      .ant-row.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
.fileList {
  display: flex;
  flex-direction: column;
}
