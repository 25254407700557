.wrapper {
  :global {
    .ant-tag {
      width: 92px;
      &.ant-tag-error {
        padding-left: 12px;
      }
      > .anticon {
        & + span {
          margin-left: 4px;
        }
      }
    }
  }
  &.loading {
    :global {
      .ant-tag {
        > .anticon {
          margin: 0;
        }
      }
    }
  }
  &.clickable {
    cursor: pointer;
  }
}
