@import 'src/assets/scss/base/constants';
@import '../style.module';

.successContent {
  text-align: center;
  > img {
    margin-bottom: $space-vertical;
  }
  .heading {
    margin-bottom: $space-vertical;
  }
  > p {
    margin-bottom: 0;
  }
  .bottomButton {
    margin-top: 36px;
  }
}
