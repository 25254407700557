@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(24, 144, 255, 0.2);
  padding-right: 12px;
}
.header {
  display: flex;
  margin-bottom: 3px;
  > div {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-right: 6px;
  }
  .form {
    width: calc(100% - 30px);
    :global {
      .ant-input.ant-input-is-blue {
        background: white !important;
        height: 29px;
      }
      .ant-row.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  :global {
    .ant-badge {
      min-width: 22px;
      .ant-badge-count {
        font-size: 12px;
        background: $dark-blue-1;
      }
    }
  }
}
.title {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  .actionBtn {
    height: 24px;
    width: 24px;
    min-width: 24px;
    margin-left: auto;
    :global {
      .anticon {
        font-size: 18px;
        transform: none;
      }
    }
  }
  .titleAction {
    margin-left: auto;
  }
  .sortAction {
    a {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: $black-6;
    }
    span {
      transform: rotate(0);
    }
    .sortActionTrigger {
      display: flex;
      align-items: center;
    }
    :global {
      .anticon {
        margin-left: 4px;
        display: inline-block;
      }
    }
  }
  :global {
    .anticon-down,
    .anticon-up {
      display: inline-flex;
      align-items: center;
      font-size: 8px;
    }
  }
}
.titleActionMenu {
  top: 0;
  margin-right: 0;
}
.tooltip {
  .tooltipContent {
    margin-right: 0;
    > div:nth-child(2) {
      min-width: 174px;
      max-width: 174px;
    }
  }
  :global {
    .ant-tooltip-inner {
      padding: 12px 16px;
      background: $orange-2;
    }
    .anticon {
      font-size: 16px;
    }
    .ant-tooltip-arrow {
      display: none !important;
    }
  }
}
