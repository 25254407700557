/**
* Colors
*/
// Primary
$primary-0: #4f4f4f;
$primary-1: #333333;

$gray-0: #e8e8e8;
$gray-1: #f2f2f2;
$gray-2: #bdbdbd;
$gray-3: #bfbfbf;
$gray-4: #c4c4c4;
$gray-5: #e0e0e0;
$gray-6: #8c8c8c;
$gray-7: #f0f0f0;
$gray-8: #cccccc;
$gray-9: #e5e5e5;
$gray-10: #9b9b9b;
$gray-11: #b8b8b8;
$gray-12: #999999;
$gray-13: #f8f7f7;
$gray-14: #717171;
$gray-15: #020202;

$white-0: white;
$white-1: #fafafa;
$white-2: #d9d9d9;
$white-3: #fcfbfb;
$white-4: #f5f5f5;
$white-5: #f7f7f7;
$white-6: #fdfdfd;

$black-0: black;
$black-1: #262626;
$black-2: #595959;
$black-3: #090909;
$black-4: #222222;
$black-5: #2c2c2c;
$black-6: #494949;
$black-7: #40586f;
$black-8: #151515;

$green-1: #b7eb8f;
$green-2: #f6ffed;
$green-3: #f2ffea;
$green-5: #48bd11;
$green-6: #52c41a;
$green-8: #0c9119;

$red-1: #f5222d;
$red-2: #fa541c;
$red-3: #ffebeb;
$red-5: #dd2a2a;
$red-6: #f05353;
$red-7: #ffa39e;
$red-8: #fff1f0;
$red-9: #ff3d3d;
$red-10: #ec5044;

$pink-1: #ffadd2;
$pink-2: #fff0f6;
$pink-3: #eb2f96;

$orange-1: #ffd591;
$orange-2: #fff7e6;
$orange-6: #fa8c16;
$orange-7: #ffa940;

$yellow-1: #faad14;

$blue-0: #1890ff;
$blue-1: #40a9ff;
$blue-1-o-1-box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
$blue-1-o-1: rgba(24, 144, 255, 0.5);
$blue-1-o-2: rgba(24, 144, 255, 0.1);
$blue-2: #e6f7ff;
$blue-3: #2f54eb;
$blue-4: #f0f5ff;
$blue-5: #adc6ff;
$blue-6: #eef7ff;
$blue-7: #f1f8ff;
$blue-8: #f5f9ff;

$dark-blue-0: #2f80ed;
$dark-blue-1: #4ba8ff;
$dark-blue-2: #141d42;

$light-blue-0: #f8fcff;

$light-green-1: #87e8de;
$light-green-2: #e6fffb;
$light-green-3: #13c2c2;

$violet-1: #d3adf7;
$violet-2: #f9f0ff;
$violet-3: #722ed1;
$violet-4: #8fa3fe;
