@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  display: flex;
  margin-bottom: 16px;
  > div {
    display: flex;
    margin-left: auto;
    > * {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  :global {
    .ant-input-affix-wrapper {
      width: 256px;
    }
    .ant-btn-default {
      color: $black-4;
    }
  }
}
