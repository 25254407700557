@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  padding: 28px 32px;
  &:not(.noData) {
    background: $light-blue-0;
  }
}
.content {
  margin-top: 28px;
  padding: 34px 38px;
}
.remindersList {
  > * {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
.loading {
  margin-left: calc(50% - 10px);
}
.notFound {
  margin-top: 80px;
}
