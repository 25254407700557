@import 'src/assets/scss/base/colors';

:global {
  .ant-modal-mask {
    background: #8c8c8c66;
  }
  .ant-modal-confirm-body {
    > .anticon {
      font-size: 21px;
      & + .ant-modal-confirm-title {
        & + .ant-modal-confirm-content {
          margin-right: 38px;
        }
      }
    }
  }
  .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
    color: $red-1;
  }
  .ant-modal-confirm-btns {
    display: flex;
  }
  .ant-modal-footer {
    > div {
      display: flex;
      justify-content: flex-end;
      > button {
        min-width: 95px;
      }
    }
  }
}
.wrapper {
  &.blueColor {
    :global {
      .ant-btn-primary {
        background: $blue-0;
        border-color: $blue-0;
      }
      .ant-btn-primary[disabled],
      .ant-btn-primary[disabled]:hover,
      .ant-btn-primary[disabled]:focus,
      .ant-btn-primary[disabled]:active {
        color: $gray-3;
        background: $white-4;
        border-color: $white-2;
      }
    }
  }
  &.hideFooter {
    :global {
      .ant-modal-body {
        padding: 0;
      }
      .ant-modal-footer {
        display: none;
      }
      .ant-modal-body-inner {
        padding: 24px;
      }
    }
  }
}
.customFooter {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid $gray-7;
  padding: 10px 16px;
  // margin: 0 -24px !important;
  > button {
    margin-right: 8px;
  }
  :global {
    .ant-row.ant-form-item {
      width: auto !important;
      margin: 0 !important;
    }
  }
}
