@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.mainContent {
  background: $blue-8;
  border-radius: 0;
  border: 1px solid $gray-9;
  display: flex;
  flex-direction: column;
  position: relative;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 22px;
    margin-bottom: 40px;
    color: $blue-0;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $black-8;
    margin-bottom: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
.innerContent {
  display: flex;
  flex: 1;
  max-width: 100%;
  overflow-x: auto;
  max-height: calc(100vh - 290px);
  > div {
    display: flex;
  }
  &.hasLoadMore {
    padding-bottom: 60px;
  }
}
.column {
  min-width: 228px;
  margin-right: 12px;
  &.addColumn {
    min-width: 216px;
    padding-right: 0;
    border-right: 0;
    margin-right: 0;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
.orderCards {
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
}
.filterOverlay {
  min-width: 205px;
  max-width: 205px;
  font-size: 12px;
  padding-bottom: 0;
  :global {
    .ant-dropdown-menu-submenu-expand-icon {
      display: none;
    }
    .ant-dropdown-menu-submenu-title {
      cursor: default;
      background: transparent !important;
      font-size: 12px;
      color: $black-3;
      padding-top: 9px;
      padding-bottom: 9px;
      box-shadow: inset 0px -1px 0px #d9d9d9;
    }
  }
  > li {
    &:first-child {
      font-size: 12px;
      border: 0;
    }
    &:last-child {
      padding-bottom: 15px;
      padding-bottom: 0;
      > div {
        box-shadow: none;
        padding: 12px 24px;
      }
    }
  }
}
.filterForm {
  display: flex;
  flex-direction: column;
  > span {
    margin-bottom: 6px;
  }
  > input {
    margin-bottom: 12px;
  }
  :global {
    .ant-input-affix-wrapper.ant-input-is-blue {
      background: $white-0 !important;
    }
    .ant-select {
      width: 100%;
    }
  }
}
.clearAllFilters {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: $blue-0;
  text-align: center;
  margin: 55px 0 4px 0;
  span {
    cursor: pointer;
  }
}
.iconActive {
  > button {
    color: $blue-0 !important;
    border-color: $blue-1-o-1 !important;
  }
}
.filtersDisplay {
  width: calc(100% - 400px);
  margin-left: auto;
}
.loadMore {
  position: absolute;
  bottom: 60px;
  left: 0;
  z-index: 2;
  right: 0;
  text-align: center;
  > button {
    margin: 0 auto;
  }
}
.clientDropdown {
  min-width: 500px !important;
}
.columnHeaderWrapper {
  position: sticky;
  top: 0;
  background: #f5f9ff;
  z-index: 1;
}
