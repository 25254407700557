@import 'src/assets/scss/base/colors';

:global {
  .ant-empty {
    display: flex;
    flex-direction: column;
  }
  .ant-empty-footer {
    > span {
      font-size: 18px;
      line-height: 32px;
      color: $black-1;
    }
  }
}
.wrapper {
  &.large {
    :global {
      .ant-empty-image {
        min-height: 152px;
      }
    }
  }
}
