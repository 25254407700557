@import 'src/assets/scss/base/colors';

:global {
  .ant-pagination-item {
    border-radius: 4px;
    a {
      color: $black-2;
    }
  }
  .ant-pagination-item-active {
    a {
      color: $blue-0;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    .anticon {
      position: relative;
      top: -1px;
    }
  }
}
.wrapper {
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}
