@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.link {
  font-size: $small-fz;
  cursor: pointer;
  color: $blue-0;
  line-height: 20px;
}
.changePasswordLink {
  width: 120px;
  font-size: 14px;
  margin-top: 6px;
}
.formContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.defaultAvatar {
  width: 160px;
  height: 160px;
  background: $gray-3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: $white-0;
  margin-bottom: $space-vertical;
}
.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &:not(.nonStyle) {
    min-width: 160px;
    img {
      width: 160px;
      height: 160px;
      margin-bottom: $space-vertical;
      border-radius: 50%;
    }
    button {
      width: 110px;
      margin-bottom: $small-space-vertical;
    }
    > p {
      margin-bottom: 0;
      font-size: $small-fz;
      color: $gray-6;
    }
  }
  .updateText {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: $white-0;
    background: $gray-3;
    border-radius: 50%;
    opacity: 0.5;
    &.hasImage {
      color: $white-0;
    }
  }
  &.hideButton {
    &:hover {
      svg {
        display: none;
      }
      .updateText {
        display: flex;
      }
    }
  }
  &.myProfileAvatar {
    .avatarDeleteIcon {
      top: 0;
      right: 20px;
    }
  }
}

.fields {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin: 0 80px;
  :global {
    .ant-form-item {
      width: calc(50% - 20px);
      margin-right: 40px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  &.isView {
    flex-direction: column;
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: $black-1;
      margin-bottom: $space;
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      > li {
        margin-bottom: $space;
        display: flex;
        flex-direction: column;
        font-size: $semi-large-fz;
        color: $black-1;
        > span {
          &:first-child {
            margin-bottom: 4px;
          }
          &:last-child {
            color: $black-2;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .strongPasswordField {
    width: calc(50% - 20px);
    margin-right: 40px;
    :global {
      .ant-form-item {
        width: 100%;
      }
    }
  }
}
.actions {
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  > button {
    &:nth-child(2) {
      margin-top: $space-vertical;
    }
  }
}
.cropperPicModal {
  :global {
    .ant-modal-body {
      padding: 0;
      position: relative;
      min-height: 550px;
      padding-bottom: 100px;
      > div {
        &:first-child {
          width: 345px;
          height: 345px;
          margin: auto;
          bottom: auto;
          top: 70px;
          > div {
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    }
    .ant-modal-footer {
      > div {
        display: flex;
        justify-content: flex-end;
      }
      button {
        width: 98px;
      }
    }
  }
  .sliderContainer {
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 65px;
    :global {
      .ant-slider {
        display: flex;
        flex: 1;
        margin: 0 20px;
        .ant-slider-rail {
          background-color: $white-2;
        }
      }
      .anticon {
        color: $black-0;
        font-size: 24px;
        cursor: pointer;
      }
      .ant-slider-handle {
        border-color: $white-2;
        border-width: 1px;
      }
      .ant-slider-track {
        background-color: $white-2;
      }
    }
  }
}
.mainContent {
  position: relative;
}
.lastUpdated {
  position: absolute;
  bottom: 40px;
  right: 40px;
  font-size: $normal-fz;
  color: $black-1;
  line-height: 22px;
}
.dragToRepositionBtn {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 136px;
  border-radius: 10px !important;
  background: $black-1 !important;
  border-color: $black-1 !important;
  color: white;
  width: 184px;
}
.myProfileWarningModal {
  max-width: 423px;
  :global {
    .ant-modal-confirm-content {
      margin-right: 0 !important;
    }
  }
}
.changePasswordForm {
  flex-direction: column;
  :global {
    .ant-form-item {
      max-width: 320px;
    }
  }
  p {
    margin-bottom: $small-space-vertical;
  }
}
.newPasswordSection {
  margin-top: $space-horizontal;
}
.iFormPasswordSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  > div {
    &:first-child {
      color: $black-1;
      margin-bottom: 6px;
    }
    &:nth-child(2) {
      color: $black-2;
    }
  }
}
.profileImageLoading {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff80;
}
.innerWrapper {
  padding: 20px;
}
.avatarDeleteIcon {
  position: absolute;
  font-size: 9px;
  width: 18px;
  height: 18px;
  z-index: 1;
  top: -10px;
  right: -10px;
  background: $red-9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white-0;
  border-radius: 50%;
  &:not(.nonStyle) {
    top: -4px;
    right: 16px;
    font-size: 12px;
    width: 22px;
    height: 22px;
  }
  svg {
    display: inline-block !important;
  }
}
.deleteAccount {
  margin-top: 24px;
  > span {
    cursor: pointer;
    color: #e83b37;
    font-size: 14px;
    font-weight: 400;
  }
}
.deleteAccountModal {
  width: 423px !important;
  :global {
    .ant-modal-confirm-body
      > .anticon
      + .ant-modal-confirm-title
      + .ant-modal-confirm-content {
      margin-right: 12px;
    }
    .ant-modal-confirm-body > .anticon {
      color: #e83b37;
    }
    .ant-modal-confirm-btns {
      .ant-btn-primary {
        background-color: #e83b37;
        border-color: #e83b37;
      }
    }
    .ant-row.ant-form-item {
      margin-top: 24px;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
      margin-right: -12px;
      .ant-col.ant-form-item-control {
        width: 100%;
      }
    }
    .ant-form-item-label {
      > label {
        font-size: 16px;
        font-weight: 600;
        color: #4f4f4f;
        &:after {
          display: none;
        }
      }
    }
  }
}
.companyPinNumber {
  margin-top: 24px;
  li {
    > span {
      &:nth-child(2) {
        display: flex;
        gap: 8px;
        align-items: center;
        :global {
          .anticon.anticon-eye {
            position: relative;
            top: -2px;
            cursor: pointer;
          }
        }
      }
    }
  }
  &.isView {
    li {
      > span {
        &:nth-child(2) {
          :global {
            .anticon.anticon-eye {
              top: 1px;
            }
          }
        }
      }
    }
  }
}
