@import 'src/assets/scss/base/colors';

.wrapper {
  width: 100%;
  :global {
    .ant-btn {
      display: inline-block;
    }
  }
}
.heading {
  font-weight: bold;
  font-size: 30px;
  color: $primary-1;
}
.logoContainer {
  text-align: center;
}

.descriptionContainer {
  padding: 0px 40px;
  margin-bottom: 0px !important;
  text-align: center;
}

.fullWidth {
  width: 100%;
}
.bottomButton {
  margin-top: 48px;
  > button {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  text-align: center;
}
