@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.header {
  margin-bottom: 28px;
}
.flex {
  display: flex;
}
.sectionTable {
  :global {
    .ant-collapse-item {
      .ant-collapse-header {
        .ant-collapse-arrow {
          & + span {
            max-width: auto;
            min-width: auto;
          }
        }
      }
    }
  }
}
