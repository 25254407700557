@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.filterTag {
  color: $black-2;
  display: inline-flex;
  align-items: center;
  background: $white-0;
  transform: rotate(180deg);
  border-color: $gray-12;
  :global {
    .anticon {
      color: $black-4;
      margin-right: 0;
      margin-left: 5px;
    }
  }
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  transform: rotate(180deg);
  position: relative;
  top: 4px;
  > * {
    margin: 4px 8px;
  }
}
