@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';
@import 'src/assets/scss/base/mixin';

.inputField {
  display: flex;
  align-items: center;
  position: relative;
  .deleteIcon {
    position: absolute;
    right: -24px;
    top: 8px;
    font-size: 16px;
    color: $red-9;
    cursor: pointer;
  }
}
.dndCard {
  align-items: center;
  > div {
    &:first-child {
      margin-right: 8px;
    }
  }
}
.buttons {
  display: flex;
  margin-top: 20px;
  button {
    font-weight: 600;
    padding-left: 0 !important;
  }
}
.wrapper {
  margin-bottom: 44px;
  > h2 {
    margin-bottom: 16px;
  }
  > p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  &.isViewDetail {
    > h2 {
      margin-bottom: 24px;
    }
  }
}
.confirmationModal {
  max-width: 423px;
  :global {
    .ant-modal-confirm-body
      > .anticon
      + .ant-modal-confirm-title
      + .ant-modal-confirm-content {
      margin-right: 0;
    }
    .ant-modal-confirm-body > .anticon {
      color: $red-1;
    }
  }
}
.checkbox {
  :global {
    .ant-checkbox {
      & + span {
        padding: 0 4px;
        cursor: default;
      }
    }
    .ant-checkbox-input {
      cursor: default;
    }
  }
}
