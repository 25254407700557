@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/mixin';

.hideCancel {
  :global {
    .ant-btn-outline {
      display: none;
    }
  }
}
.blueColor {
  :global {
    .ant-btn-primary {
      background: $blue-0;
      border-color: $blue-0;
    }
  }
}
.revertButton {
  :global {
    .ant-modal-confirm-btns {
      > button {
        &:nth-child(1) {
          order: 2;
          margin-left: 0;
        }
        &:nth-child(2) {
          order: 1;
          margin-right: 8px;
          border-color: $white-2 !important;
          color: $black-2 !important;
        }
      }
    }
  }
}
