@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.form {
  display: flex;
  flex-direction: column;
  .formInner {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: calc(50% - 20px);
      &:nth-child(2n + 1) {
        margin-right: 40px;
      }
    }
  }
  .customItem {
    display: flex;
    :global {
      .ant-row.ant-form-item {
        &:first-child {
          width: 50px;
          margin-right: 40px;
          & + div {
            flex: 1;
          }
        }
      }
    }
  }
  :global {
    .ant-row.ant-form-item {
      .ant-row.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
.modal {
  :global {
    .ant-modal-body {
      min-height: 500px;
    }
  }
}
