@import 'src/assets/scss/base/colors';

.wrapper {
  :global {
    .ant-menu-item {
      a {
        color: $black-2;
        .anticon {
          color: $black-2;
        }
      }
      &:first-child {
        margin-top: 0;
      }
      &:after {
        border-color: $primary-0 !important;
      }
      &:hover,
      &.ant-menu-item-selected {
        background-color: $gray-5;
      }
      &.item-collapsed {
        text-overflow: initial;
        > a {
          .item-child-text {
            display: none;
          }
          .anticon {
            margin: 0;
          }
        }
      }
    }
  }
  &.isCollapsed {
    color: red;
  }
}
