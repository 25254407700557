@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  background: $white-0;
  border-radius: 10px;
  padding: 40px;
  color: $black-1;
  position: relative;
  &.initFullHeight {
    min-height: calc(100vh - #{$page-content-minus-height});
  }
  &.noBackground {
    background: transparent;
  }
  &.noPadding {
    padding: 0;
  }
  &.hasMargin {
    margin: 24px;
  }
}
