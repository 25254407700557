.wrapper {
  p {
    margin: 0;
  }
}
.form {
  display: flex;
  flex-direction: column;
  .formInner {
    display: flex;
    flex-wrap: wrap;
    > div {
      &:nth-child(2n + 1) {
        margin-right: 40px;
      }
    }
  }
  .customItem {
    display: flex;
    :global {
      .ant-row.ant-form-item {
        &:first-child {
          width: 50px;
          margin-right: 40px;
        }
      }
    }
  }
  :global {
    .ant-row.ant-form-item {
      width: 320px;
    }
  }
}
.bottomButton {
  margin-top: 20px;
  :global {
    .ant-row.ant-form-item {
      width: 100%;
      margin: 0;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
  }
}
