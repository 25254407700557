@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  position: relative;
}
.actionButtons {
  display: flex;
  position: absolute;
  top: -62px;
  right: 0;
  > * {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.unlockModal {
  :global {
    .ant-modal-content {
      color: $primary-1;
    }
    .ant-modal-header,
    .ant-modal-footer {
      border: 0;
    }
    .ant-modal-header {
      padding: 50px 50px 0 50px;
    }
    .ant-modal-body {
      padding: 16px 50px 40px 50px;
    }
    .ant-modal-footer {
      padding: 0 50px 50px 50px;
      button {
        width: 100%;
      }
    }
    .ant-modal-title {
      color: $primary-1;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
    }
  }
  .description {
    margin-bottom: 60px;
    font-size: 14px;
    line-height: 22px;
  }
  label {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 6px;
    color: $primary-0;
    display: block;
  }
}
.simpleView {
  padding: 0 20px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    li {
      list-style: none;
      color: #262626;
      display: flex;
      flex-direction: column;
      > span {
        font-size: 16px;
        line-height: 24px;
        word-break: break-all;
        &:nth-child(2) {
          color: #090909;
          font-size: 14px;
          line-height: 22px;
          margin-top: 5px;
        }
      }
    }
  }
}
.fullView {
  .fullViewFormSection {
    :global {
      .ant-input.ant-input-is-blue {
        background: $white-4 !important;
        color: $gray-3;
      }
    }
    input::placeholder {
      color: #bfbfbf80 !important;
    }
  }
}
.fullWidth {
  min-width: 100%;
  max-width: 100%;
}
.title {
  border-bottom: 1px solid rgba(24, 144, 255, 0.5);
  padding-bottom: 14px;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 28px;
  color: #2c2c2c;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  min-width: 100% !important;
  max-width: 100% !important;
  margin-top: 24px;
}
.deleteLink {
  position: absolute;
  top: 12px;
  right: 0;
  display: flex;
  > span {
    cursor: pointer;
    color: $red-9;
    font-weight: 500;
  }
}
.simpleViewLoading {
  position: absolute;
  left: 0;
  right: 0;
}
