@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.sideBar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: $sidebar-width;
  top: 0px;
  bottom: 0px;
  padding: 19px 0px 25px 0px;
  background: $white-0;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  transition: all 0.2s;
  z-index: 2;
  &.isCollapsed {
    width: $sidebar-collapsed-width;
  }
  :global {
    .ant-menu-inline > .ant-menu-item {
      height: auto;
      line-height: 22px;
      min-height: 40px;
      display: flex;
      align-items: center;
      padding-top: 9px;
      padding-bottom: 9px;
      text-overflow: initial;
      white-space: initial;
      > a {
        display: flex;
        > .anticon {
          margin-top: 4px;
        }
      }
    }
  }
}
.chatCollapseIcon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  span {
    cursor: pointer;
  }
  :global {
    .anticon {
      font-size: 16px;
      background: #428ff7;
      border-radius: 2px;
      padding: 4px;
      color: white;
    }
  }
}
.chatContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: $chat-width;
  top: 0px;
  bottom: 0px;
  background: $white-0;
  padding-top: 22px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  transition: all 0.2s;
  z-index: 2;
  right: 0;
  &.isCollapsed {
    width: $chat-collapsed-width;
    .chatCollapseIcon {
      justify-content: center;
    }
  }
  > div {
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.globalSearch {
  width: 195px !important;
}
.logoContainer {
  transition: all 0.2s;
  height: 26px;
  overflow: hidden;
  color: $black-0;
  font-size: $normal-fz;
  line-height: 22px;
  font-weight: normal;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    width: 19px;
    height: 23px;
    // border-radius: 50%;
    // background: $gray-4;
    margin-right: $semi-small-space-horizontal;
    + span {
      min-width: 122px;
    }
  }
  &.isCollapsed {
    .logo {
      margin-right: 0;
      + span {
        display: none;
      }
    }
  }
}
.toggleNav {
  margin-left: 5px;
  display: flex;
  > span {
    font-size: 24px;
    cursor: pointer;
  }
}
.componentContainer {
  transition: all 0.2s;
  padding: 0 $chat-width 0 $sidebar-width;
  &.isCollapsed {
    padding-left: $sidebar-collapsed-width;
  }
  &.isChatCollapsed {
    padding-right: $chat-collapsed-width;
  }
}
.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  padding: $space-vertical 24px;
  background: $white-0;
  border-bottom: 1px solid $white-2;
  > div {
    &:first-child {
      :global {
        .anticon {
          font-size: 19px;
          color: $black-2;
        }
      }
      cursor: pointer;
    }
    &:last-child {
      display: flex;
      align-items: center;
      > div {
        margin-right: $semi-small-space-horizontal;
      }
    }
  }
}
.mainContentWrapper {
  background: $white-1;
  padding: $space-vertical $space-horizontal;
  min-height: calc(100vh - 64px);
  &.noPadding {
    padding: 0;
  }
  &.isWhite {
    background: $white-0;
  }
}
.userAvatar {
  width: 32px;
  height: 32px;
  background: $gray-3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white-0;
  :global {
    .anticon {
      font-size: $semi-large-fz;
    }
  }
  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &.hadImage {
    background: transparent;
  }
}
.profileDropdown {
  color: $black-1 !important;
  :global {
    .anticon {
      font-size: 10px;
      vertical-align: baseline;
    }
  }
}
.profileDropdownMenu {
  margin-top: 15px;
  width: 164px;
  :global {
    .ant-dropdown-menu-item {
      > a {
        > .anticon {
          font-size: $normal-fz;
          margin-right: 10px;
        }
      }
    }
  }
}
.emptyIcon {
  width: $normal-icon-width;
  height: $normal-icon-height;
  border-radius: 50%;
  background: $gray-4;
}
.notification {
  display: flex;
  margin-right: 20px !important;
  :global {
    .anticon {
      font-size: 21px;
      color: $black-2;
    }
  }
  > div {
    margin-right: 22px;
  }
}
