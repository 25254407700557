@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  > div {
    display: flex;
  }
  span {
    font-size: 13px;
    color: $black-6;
  }
  > span {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    text-transform: uppercase;
    color: $gray-4;
    margin-bottom: 8px;
    display: inline-block;
    width: 100%;
    text-align: right;
  }
  .info {
    margin-bottom: 20px;
    span {
      line-height: 16px;
      font-weight: normal;
      &:first-child {
        font-weight: 600;
      }
    }
  }
  .separate {
    margin: 0 5px;
  }
  .contacts {
    justify-content: flex-end;
    > * {
      margin-right: 6px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  :global {
    span.anticon {
      font-size: 15px;
      color: $blue-0;
      position: relative;
      top: -1px;
    }
  }
}
