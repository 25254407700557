.wrapper {
  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #262626;
  }
  :global {
    .ant-empty-image {
      height: auto;
      > img {
        width: 160px;
        height: auto;
      }
    }
  }
}
