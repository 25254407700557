@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/mixin';

:global {
  .ant-form-item-label {
    > label {
      color: $black-1;
    }
  }
}
.requiredMark {
  @include required-mark;
}
.hide {
  display: none;
}
.tooltipOverlay {
  margin-bottom: 10px;
  max-width: 330px;
  :global {
    .ant-tooltip-inner,
    .ant-tooltip-arrow-content {
      background-color: $orange-2;
    }
    .ant-tooltip-inner {
      padding: 12px 16px 12px 10px;
    }
  }
}
.formItem {
  &.noMargin {
    margin-bottom: 0 !important;
  }
}
