@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $black-6;
    margin-bottom: 0;
  }
}
