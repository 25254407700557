@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

:global {
  .ant-input {
    border-color: $gray-2;
    &:focus {
      border-color: $gray-2;
      box-shadow: none;
      background: $gray-1;
      -webkit-box-shadow: 0 0 0px 1000px $gray-1 inset !important;
    }
    &:hover {
      border-color: $gray-2;
    }
    &::placeholder {
      color: $gray-3 !important;
    }
    &.ant-input-is-blue {
      background: transparent !important;
      &:focus {
        border-color: $blue-1;
        box-shadow: $blue-1-o-1-box-shadow !important;
      }
      &:hover {
        border-color: $blue-1;
      }
    }
    &[disabled] {
      background-color: $white-4 !important;
      border-color: $white-2 !important;
    }
  }
  .ant-input-prefix {
    .anticon {
      color: $gray-3;
    }
  }
  .ant-input-affix-wrapper {
    border-color: $gray-2;
    &:focus {
      border-color: $gray-2;
      box-shadow: none;
      background: $gray-1;
    }
    &:hover {
      border-color: $gray-2;
    }
    .ant-input-clear-icon {
      font-size: $normal-fz;
      color: $blue-0;
    }
    &.ant-input-is-blue {
      background: transparent !important;
      &:focus {
        border-color: $blue-1;
        box-shadow: $blue-1-o-1-box-shadow !important;
      }
      &:hover {
        border-color: $blue-1;
      }
    }
  }
  .ant-input-affix-wrapper-focused {
    border-color: $gray-2;
    box-shadow: none;
    background: $gray-1;
    > input {
      -webkit-box-shadow: 0 0 0px 1000px $gray-1 inset !important;
    }
    &.ant-input-is-blue {
      border-color: $blue-1;
      box-shadow: $blue-1-o-1-box-shadow !important;
      > input {
        box-shadow: none !important;
        background: transparent !important;
      }
    }
  }
  .ant-form-item-has-error {
    .ant-input-affix-wrapper {
      &.ant-input-affix-wrapper-focused {
        background: $gray-1;
      }
    }
  }
}
