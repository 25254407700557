@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.actionButtons {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  &.empty {
    justify-content: center;
    &.hideNotFound {
      justify-content: flex-end;
    }
  }
}
.fieldBlock {
  margin-bottom: 24px;
}
.wrapper {
  &.viewEmpty {
    padding-bottom: 60px;
  }
}
.notFound {
  margin-bottom: 24px;
  margin-top: 28px;
}
