@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

:global {
  .ant-spin-nested-loading {
    > div {
      .ant-spin {
        max-height: 100%;
      }
    }
  }
}

.wrapper {
  &:not(.icon) {
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffffcc;
    z-index: 1000;
    flex-direction: column;
  }
}
