@import 'src/assets/scss/base/colors';

:global {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $blue-2;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $gray-2;
  }
}
.dropdownIsMultipleOptionCheckbox {
  :global {
    .ant-select-item-option-content {
      order: 2;
    }
    .ant-select-item-option-state {
      .ant-checkbox + span {
        padding-right: 4px;
        padding-left: 4px;
      }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: $blue-1-o-2;
    }
    .ant-checkbox-wrapper .ant-checkbox {
      // top: 3px;
    }
  }
}
.tooltipOverlay {
  :global {
    .ant-tooltip-inner,
    .ant-tooltip-arrow-content {
      background-color: $orange-2;
    }
    .ant-tooltip-content,
    .ant-tooltip-arrow {
      margin-top: 4px;
    }
    .ant-tooltip-inner {
      padding: 12px 16px 12px 10px;
    }
  }
}
.optionHasTooltip {
  padding: 0;
  :global {
    .ant-select-item-option-content {
      display: flex;
    }
  }
  .tooltipOptionContent {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 5px 12px;
  }
}
.selectWrapper {
  &.isBlue {
    :global {
      .ant-select-selection-overflow-item {
        font-size: 12px;
        .ant-select-selection-item {
          background: rgba(24, 144, 255, 0.1);
          border-color: $blue-0;
          color: $black-4;
          .ant-select-selection-item-remove {
            color: $black-4;
            font-size: 12px;
          }
        }
      }
    }
  }
  &.isAllValue {
    :global {
      .ant-select-multiple {
        .ant-select-selection-item {
          background: transparent;
          border: 0;
          .ant-select-selection-item-remove {
            display: none;
          }
        }
      }
    }
  }
}
.dropdownIsBlue {
  :global {
    .ant-select-item.ant-select-item-option {
      &.ant-select-item-option-selected {
        background: transparent;
        font-weight: normal;
      }
      &.ant-select-item-option-active {
        background: rgba(24, 144, 255, 0.1);
        font-weight: normal;
      }
    }
  }
}
.dropdownSeeFullOption {
  :global {
    .ant-select-item-option-content {
      text-overflow: initial;
      white-space: initial;
    }
    .ant-select-item-option-state {
      display: flex;
    }
  }
}
.lineAtAllOption {
  :global {
    .rc-virtual-list-holder-inner {
      .ant-select-item {
        &:first-child {
          position: relative;
          margin-bottom: 10px;
          &:after {
            display: block;
            width: calc(100% - 8px);
            height: 1px;
            border-top: 1px solid #e8e8e8;
            position: absolute;
            bottom: -5px;
            content: ' ';
            left: 4px;
          }
          .ant-select-item-option-state {
            display: none;
          }
        }
      }
    }
  }
}
.wrapper {
  &.isCustomMultipleSelected {
    :global {
      .ant-select-selector {
        > span {
          display: none;
          &.ant-select-selection-search,
          &.ant-select-selection-placeholder {
            display: block;
          }
          &:first-child {
            display: block;
          }
        }
        .ant-select-selection-overflow {
          > div {
            display: none;
            &:first-child,
            &:last-child {
              display: block;
            }
          }
        }
        .custom-selected-tag {
          padding: 0 7px;
          position: relative;
        }
      }
    }
  }
}
.borderFirstOption {
  :global {
    .rc-virtual-list-holder-inner {
      > div {
        &:first-child {
          background: transparent !important;
          position: relative;
          &:after {
            content: ' ';
            display: block;
            position: absolute;
            height: 1px;
            border-top: 1px solid #e8e8e8;
            left: 4px;
            right: 4px;
            bottom: 0;
          }
        }
      }
    }
  }
}
