.statusEl {
  cursor: pointer;
}
.wrapperOverlay {
  :global {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner-content {
      padding: 0;
      border-radius: 2px;
      min-width: 375px;
      max-width: 375px;
    }
    .ant-badge-count {
      box-shadow: none !important;
      font-weight: 600;
    }
    .ant-tabs-nav {
      padding: 0;
      .ant-tabs-nav-wrap {
        &:before,
        &:after,
        & + .ant-tabs-nav-operations {
          display: none;
        }
        .ant-tabs-nav-list {
          width: 100%;
          .ant-tabs-tab {
            text-align: center;
            margin-right: 0;
            min-width: 50%;
            max-width: 50%;
            min-height: 40px;
            border: 0;
            background: #d9d9d9;
            border-radius: 2px 2px 0px 0px;
            color: #9b9b9b;
            .ant-tabs-tab-btn {
              width: 100%;
              > span {
                display: flex;
                align-items: center;
                justify-content: center;
                :global {
                  .ant-badge {
                    margin-left: 8px;
                  }
                }
              }
            }
            &.ant-tabs-tab-active {
              background: #f5f5f5;
              font-weight: 600;
              color: black;
              .ant-tabs-tab-btn {
                color: black;
              }
            }
            &:not(.ant-tabs-tab-active) {
              .ant-tabs-tab-btn {
                > span {
                  :global {
                    .ant-badge {
                      .ant-badge-count {
                        background: #9b9b9b;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-tabs {
      .ant-tabs-content-holder {
        padding: 0;
      }
    }
  }
}
