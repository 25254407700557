@import 'src/assets/scss/base/colors';

:global {
  .ant-input-textarea {
    &.ant-input-is-blue {
      .ant-input {
        background: transparent !important;
        &:focus {
          border-color: $blue-1;
          box-shadow: $blue-1-o-1-box-shadow !important;
        }
        &:hover {
          border-color: $blue-1;
        }
      }
    }
  }
  .ant-form-item .ant-input-textarea-show-count::after {
    color: $gray-3;
    font-weight: 400;
    line-height: 22px;
    margin-top: 2px;
  }
}
.textareaWrapper {
  &.captionAsCount {
    :global {
      .ant-textarea-caption {
        color: $gray-3;
        font-weight: 400;
        line-height: 22px;
        float: right;
      }
    }
  }
}
