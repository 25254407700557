@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.header {
  margin-bottom: 28px;
}
.additionalLabel {
  > span {
    &:first-child {
      > span {
        &:nth-child(2) {
          max-width: calc(67% - 0px);
          width: calc(67% - 0px);
          min-width: 200px;
        }
      }
    }
  }
}
.panelHeader {
  > span {
    > span {
      &:nth-child(2) {
        width: calc(100% - 180px);
        max-width: calc(100% - 180px);
        min-width: 200px;
      }
    }
  }
}
.panel {
  :global {
    .ant-collapse-header {
      align-items: flex-start !important;
    }
    .ant-collapse-extra {
      margin-top: 6px;
    }
  }
}

@mixin headerFirstMedium() {
  > span {
    > span {
      &:nth-child(2) {
        > span {
          &:nth-child(1) {
            > span {
              max-width: calc(67% - 0px) !important;
              width: calc(67% - 0px) !important;
              min-width: 200px !important;
            }
          }
        }
      }
    }
  }
}
@mixin headerAllMedium() {
  > span {
    > span {
      &:nth-child(2) {
        > span {
          > span {
            max-width: 100% !important;
            width: 100% !important;
            min-width: 200px !important;
          }
          &:nth-child(1) {
            > span {
              max-width: 100% !important;
              width: 100% !important;
              min-width: 200px !important;
            }
          }
        }
      }
    }
  }
}
@mixin headerSmall($margin: 10px) {
  > span {
    > span {
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      &:nth-child(2) {
        margin-top: $margin;
      }
    }
  }
}
@media screen and (max-width: 1644px) {
  .panelHeader {
    &.isActive {
      @include headerFirstMedium();
    }
  }
}
@media screen and (max-width: 1639px) {
  .panelHeader {
    &.isActive {
      @include headerAllMedium();
    }
  }
}
@media screen and (max-width: 1561px) {
  .panelHeader {
    @include headerFirstMedium();
  }
}
@media screen and (max-width: 1556px) {
  .panelHeader {
    @include headerAllMedium();
  }
}
@media screen and (max-width: 1389px) {
  .panelHeader {
    &.isActive {
      @include headerSmall();
    }
    &.isEdit {
      @include headerSmall(20px);
    }
  }
}
@media screen and (max-width: 1305px) {
  .panelHeader {
    @include headerSmall();
  }
}
