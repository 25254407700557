.buttonContainer {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  &.background {
    background: #e5e5e5;
  }
}
.avatarContainer {
  div {
    margin: 5px;
    > span {
      margin: 5px;
    }
  }
}
