@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

:global {
  .ant-badge-count {
    font-size: $normal-fz;
    background: $blue-0;
    justify-content: center;
    &.ant-badge-count-sm {
      font-size: 12px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-scroll-number-only {
        height: 16px;
        position: relative;
        top: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-scroll-number-only-unit {
          height: 18px;
        }
      }
    }
  }
}
.wrapper {
}
