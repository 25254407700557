@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.other {
  margin-top: 36px;
}
.tabs {
  :global {
    .ant-tabs-nav {
      background: rgba(24, 144, 255, 0.1);
      border-radius: 4px;
      padding: 0 10px;
      margin-bottom: 30px;
    }
    .ant-tabs-tab-btn {
      color: $black-7;
    }
    .ant-tabs-tab {
      padding: 10px;
      margin-right: 8px;
      &.ant-tabs-tab-active,
      &:hover {
        background: $blue-0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
          0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        .ant-tabs-tab-btn {
          color: $white-0;
          font-weight: normal;
        }
      }
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: 600;
        }
      }
    }
    .ant-tabs-content-holder {
      padding: 0;
    }
    .ant-tabs-ink-bar {
      display: none !important;
    }
  }
}
