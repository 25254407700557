@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.modal {
  :global {
    .ant-modal {
      padding-bottom: 0;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
    }
    .ant-modal-body {
      padding-bottom: 0;
    }
  }
  .orderModalStep {
    :global {
      .ant-steps-item-content > .ant-steps-item-description {
        color: $blue-0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    }
  }
}
.rushOrderInfo,
.paymentMethodAch {
  max-width: 240px;
  :global {
    .ant-popover-inner {
      background-color: $orange-2;
    }
    .ant-popover-content {
      > .ant-popover-arrow {
        border-color: $orange-2;
      }
    }
    .ant-popover-inner-content {
      display: flex;
      align-items: flex-start;
      color: $black-7;
      p {
        margin-bottom: 0;
      }
    }
    .anticon {
      color: $orange-7;
      padding-right: 8px;
    }
  }
}
.stepContent {
  min-height: 350px;
  margin-top: 6px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -20px;
  padding-right: 20px;
  padding-bottom: 24px;
  padding-top: 24px;
  .stepTitle {
    text-align: center;
    margin-bottom: 30px;
  }
  &.listInfo {
    > ul {
      padding: 0;
    }
  }
  .inputHighlight:not(:global(.ant-form-item-has-success)) {
    :global {
      .ant-picker,
      .ant-input,
      .ant-select-selector,
      .ant-select .ant-select-selector {
        background: $blue-7 !important;
        border-color: $blue-1;
        box-shadow: 0 0 4px $blue-0;
      }
    }
  }
}
.elementTitle {
  color: $blue-0;
}
.subTitle {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.paymentWrapper {
  .paymentInnerContent {
    display: flex;
    width: 100%;
  }
  .newPaymentMethodWrapper {
  }
  .paymentInputs {
    width: 50%;
    padding: 0 15px 0 0;
    .paymentMethod {
      margin-top: 0;
    }
  }
  .noteField {
    textarea {
      min-height: 133px;
    }
    :global {
      .ant-row.ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .orderSummary {
    width: 50%;
    .summaryList {
      padding: 0;
    }
  }
  .paymentOverview {
    width: 50%;
    padding: 0 0 0 15px;
    .paymentOverviewInnerContent {
      background: #1890ff1a;
      padding: 23px 35px;
      border-radius: 5px;
      .total {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #595959;
        padding-top: 8px;
        border-top: 0.5px solid #c4c4c4;
        margin-top: 8px;
      }
      .subtotal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > * {
          margin-bottom: 0;
          font-weight: 500;
        }
        > label {
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          color: #595959;
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        :global {
          .ant-form-item {
            width: 100px;
            .ant-input-number-input {
              text-align: right;
              padding-left: 11px;
            }
          }
        }
      }
      .grandTotal {
        display: flex;
        justify-content: space-between;
        > * {
          margin-bottom: 0;
          font-weight: 800;
        }
      }
      .rushOrder {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .infoIcon {
          position: relative;
          top: -1px;
        }
        .rushOrderInput {
          margin-bottom: 0;
          .infoIcon {
            margin-left: 5px;
          }
          :global {
            .ant-checkbox-wrapper .ant-checkbox + span {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}

.paymentMethodDropDown {
  :global {
    .ant-select-selector .ant-select-selection-item,
    .ant-select-item-option-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .ant-select-selector .ant-select-selection-item:after {
      display: none;
    }
  }
}

.orderPriceInput {
  :global {
    .ant-form-item-explain {
      position: absolute;
      bottom: -27px;
      width: 200px;
      left: -5px;
    }
  }
}
.rushOrderInfo {
  :global {
    .ant-popover-content {
      margin-left: -13px;
    }
    .ant-popover-content {
      > .ant-popover-arrow {
        left: 3px;
      }
    }
  }
}
.addItemBlock {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.hasBorderTop {
  border-top: 1px solid #d9d9d9;
  padding-top: 32px;
  margin-bottom: 24px;
}
.parentSection {
  border-top: 1px solid #d9d9d9;
  padding-top: 32px;
}
.cardDeclineModal {
  :global {
    .ant-modal-confirm-btns {
      float: none;
      justify-content: center;
      > button {
        min-width: 98px;
      }
    }
    .ant-modal-confirm-title,
    .anticon.anticon-info-circle {
      color: $red-9;
    }
  }
}
