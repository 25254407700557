@import 'src/assets/scss/base/colors';

@mixin column-action-responsive {
  position: absolute;
  left: -5px;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin required-mark {
  color: $red-6;
  margin-left: 5px;
}
@mixin tooLongText($line: 1) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
}
@mixin ellipsisLongText() {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin hideScrollBar() {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
