@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';
@import 'src/assets/scss/base/mixin';

.wrapper {
}
.innerWrapper {
  padding: 20px 24px;
}
.mainContent {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 110px) !important;
  :global {
    .ant-form {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      > div:first-child {
        flex: 1;
        display: flex;
      }
    }
  }
}
.actions {
  display: flex;
  justify-content: flex-end;
  padding: $space-vertical 12px;
  background: rgba(196, 196, 196, 0.3);
  border-radius: 0 0 10px 10px;
  margin: 0 -40px;
  z-index: 10;
  position: sticky;
  bottom: 0;
  > button {
    margin-right: $semi-small-space-horizontal;
    &:last-child {
      margin-right: 0;
    }
  }
}
.formContent {
  display: flex;
  flex-direction: column;
  > * {
    min-width: 100%;
  }
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > * {
      min-width: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
    &.customLine {
      > * {
        max-width: 100%;
        min-width: 100%;
      }
      > div > div > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        > div {
          max-width: calc(50% - 20px);
          min-width: calc(50% - 20px);
        }
      }
    }
  }
  :global {
    .ant-col.ant-form-item-label {
      font-weight: bold;
    }
  }
}
.statusButton {
  margin-right: auto !important;
}
.information {
  font-size: 14px;
  flex: 1;
  > div {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    &.topInformation {
      margin-bottom: 100px;
    }
    > div {
      flex: 1;
      max-width: calc(50% - 20px);
      min-width: calc(50% - 20px);
      display: flex;
      flex-direction: column;
      > span {
        &:first-child {
          font-weight: bold;
          display: inline-block;
          margin-bottom: 8px;
        }
        &:nth-child(2) {
          padding-left: 12px;
          font-weight: 400;
          white-space: pre-wrap;
        }
      }
    }
  }
}
.mainTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
}
