@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';
@import 'src/assets/scss/base/mixin';

.wrapper {
  &.isDetail {
    .actions {
      margin: 0 -32px 0 -40px;
    }
  }
  &.isViewDetail {
    .formContent {
      padding-bottom: 50px;
    }
    :global {
      .ant-form-item {
        &:not(.ant-file-field) {
          > .ant-form-item-label {
            font-weight: bold;
          }
        }
      }
    }
    .availableFieldLine {
      > div {
        &:nth-child(1) {
          > span {
            &:first-child {
              @include tooLongText(2);
            }
          }
        }
      }
    }
  }
  &.isActivityCollapsed {
    .activity {
      width: 80px;
      max-width: 80px;
      min-width: 80px;
    }
    .mainContent {
      &:not(.hideNewActivity) {
        :global {
          form.ant-form.ant-form-vertical {
            max-width: calc(100% - 80px);
          }
        }
      }
    }
  }
}
.innerWrapper {
  padding: 20px 24px;
}
.mainContent {
  padding-bottom: 0;
  &:not(.hideNewActivity) {
    padding: 0;
    display: flex;
    flex-direction: row;
    :global {
      form.ant-form.ant-form-vertical {
        padding: 40px 32px 0px 40px;
        max-width: calc(100% - 325px);
        flex: 1;
      }
    }
    .rightBlock {
      .headerChild {
        right: 0;
      }
    }
  }
}
.formContent {
  display: flex;
  flex-wrap: wrap;
  h2 {
    font-size: $large-fz;
    font-weight: 600;
    color: $black-1;
    line-height: 28px;
    margin-bottom: $space;
  }
}
.leftBlock,
.rightBlock {
  min-width: 50%;
  max-width: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .headerChild {
    position: absolute;
    right: 56px;
    top: 0;
  }
}
.leftBlock {
  padding-right: 20px;
  > div {
    > div {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.rightBlock {
  padding-left: 20px;
}
.tabBlock {
  .tabHeader {
    display: flex;
    color: $black-2;
    > div {
      margin-right: $space-horizontal;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        font-weight: 600;
        color: $blue-0;
        border-bottom: 2px solid $blue-0;
      }
    }
  }
  .tabContent {
    margin-top: 6px;
    :global {
      .ant-form-item {
        max-width: 240px;
      }
    }
  }
  &.disabled {
    .tabHeader {
      > div {
        cursor: default;
      }
    }
  }
}
.actions {
  display: flex;
  justify-content: flex-end;
  padding: $space-vertical 12px;
  background: rgba(196, 196, 196, 0.3);
  border-radius: 0 0 10px 10px;
  margin: 0 -40px;
  z-index: 10;
  position: sticky;
  bottom: 0;
  > button {
    margin-right: $semi-small-space-horizontal;
    &:last-child {
      margin-right: 0;
    }
  }
}
.orderTypeFields {
  width: 100%;
  margin-bottom: $large-space-vertical;
}
.fieldsHeader {
  display: flex;
  > h2 {
    flex: 1;
    min-width: 50%;
    max-width: 50%;
    &:last-child {
      padding-left: $space;
    }
  }
}
.availableFieldLine {
  display: flex;
  color: $black-2;
  > div {
    flex: 1;
    text-align: center;
    &:nth-child(1) {
      min-width: calc(100% - 200px);
      max-width: calc(100% - 200px);
      text-align: left;
      padding-left: $space-horizontal;
      > span {
        cursor: pointer;
      }
    }
    &:nth-child(2) {
      min-width: 150px;
      max-width: 150px;
      display: flex;
      > div {
        display: flex;
        width: 100%;
        > label {
          margin: 0;
          flex: 1;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &:nth-child(3) {
      min-width: 50px;
      max-width: 50px;
    }
  }
  &.dRight {
    > div {
      &:first-child {
        min-width: 100%;
        max-width: 100%;
        padding-left: 0 !important;
      }
    }
  }
  &.isChild {
    padding-left: $small-space-horizontal;
    &:global(.child-level-2) {
      padding-left: 32px;
      :global {
        .ant-input {
          margin-left: -16px;
        }
      }
    }
    &:global(.child-level-3) {
      padding-left: 48px;
      :global {
        .ant-input {
          margin-left: -32px;
        }
      }
    }
    &:global(.child-level-4) {
      padding-left: 64px;
      :global {
        .ant-input {
          margin-left: -48px;
        }
      }
    }
    &:global(.child-level-5) {
      padding-left: 80px;
      :global {
        .ant-input {
          margin-left: -64px;
        }
      }
    }
  }
  :global {
    .ant-radio-wrapper {
      cursor: default;
      > span {
        cursor: pointer;
      }
    }
  }
}
.required {
  @include required-mark;
  display: inline-block;
}
.rangePrice {
  display: flex;
  align-items: flex-start;
  > div {
    margin-bottom: 0;
    width: 240px;
    &:first-child {
      :global {
        .ant-form-item-explain-error {
          > div {
            width: 500px;
          }
        }
      }
    }
  }
  > span {
    margin: 6px 15px 0 15px;
    color: $gray-3;
  }
  &.isViewDetail {
    > div {
      width: auto;
    }
    > span {
      margin: 4px 6px 0 6px;
      & + div {
        label {
          padding-left: 0;
        }
      }
    }
    label {
      color: $black-2;
    }
  }
}
.confirmationModal {
  width: 450px !important;
  :global {
    .ant-modal-confirm-body
      > .anticon
      + .ant-modal-confirm-title
      + .ant-modal-confirm-content {
      margin-right: 0;
    }
  }
}
.redIconModal {
  :global {
    .ant-modal-confirm-body > .anticon {
      color: $red-1;
    }
  }
}
.notAskMeCheckbox {
  display: block;
  margin-top: $small-space-vertical;
  width: 190px;
}
.label {
  padding-left: 12px;
  display: flex;
  color: $black-1;
  white-space: break-spaces;
}
.detailTimeline {
  margin-top: 40px;
  :global {
    .ant-timeline {
      padding-top: 5px;
    }
  }
}
.pricing {
  :global {
    .ant-form-item {
      max-width: 240px;
    }
  }
}
.activity {
  width: 325px;
  max-width: 325px;
  min-width: 325px;
  > div {
    min-height: 816px;
  }
  &.isActivityCollapsed {
    width: 80px;
    max-width: 80px;
    min-width: 80px;
  }
}
.otherSection {
  margin-top: 10px;
  margin-bottom: 40px;
}
.deleteOrderBtn {
  margin-right: auto !important;
}
.checklistInternalDocumentWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  > * {
    min-width: calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}
