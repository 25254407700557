@import 'src/assets/scss/base/colors';
@import 'src/assets/scss/base/constants';

.modal {
  :global {
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-header {
      display: none !important;
    }
    .ant-modal-body {
      padding: 32px 32px 24px 32px;
    }
    .ant-modal-footer {
      border: 0;
      padding: 0 32px 32px 32px;
    }
  }
  &.step_1 {
  }
  &.step_2,
  &.step_3 {
    :global {
      .ant-modal-footer {
        border: 0;
        > div {
          display: flex;
          flex-direction: column;
          gap: 16px;
          > button {
            margin: 0;
            font-size: 18px;
          }
        }
        .ant-btn-default {
          order: 2;
          border: 0;
          height: 20px;
          width: 70px;
          padding: 0;
          min-width: 70px;
          margin: auto;
        }
        .ant-btn-primary {
          order: 1;
          font-size: 20px;
          background: #ec5044 !important;
          font-weight: 700;
          min-height: 52px;
          border-color: #ec5044;
        }
      }
    }
  }
  &.step_3 {
    .step2 {
      > p {
        margin: 0;
      }
    }
  }
}
.step1 {
  display: flex;
  gap: 16px;
  > p {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #595959;
    > span {
      &:nth-child(1) {
        font-size: 16px;
        font-weight: 600;
        color: #262626;
      }
    }
  }
  :global {
    .anticon {
      font-size: 24px;
      color: #faad14;
    }
  }
}
.step2,
.step3 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > p {
    font-size: 18px;
    color: #4f4f4f;
    line-height: 22px;
    margin: 0 0 22px 0;
  }
  > h2 {
    font-size: 30px;
    font-weight: 700;
    color: #333333;
    line-height: 38px;
    margin: 0;
  }
  :global {
    .ant-form-item-label {
      > label {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #4f4f4f;
      }
    }
    .ant-input-affix-wrapper-lg {
      border-radius: 4px;
    }
  }
}
